import dayjs from "dayjs";
import { debounce } from "lodash";
import Utils from "../../../utils/Utils";
import { useCallback, useState } from "react";
import Comms from "../../../models/Communication";
import { findOption } from "../../../utils/Forms";
import FormInput from "../../../components/form/FormInput";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faFilter, faFilterCircleXmark } from "@fortawesome/free-solid-svg-icons";

const Filters = ({ filters, handleFilterChange, handleClearFilters }) => {
  const [subject, setSubject] = useState("");
  const to = filters?.to ? dayjs(new Date(filters?.to).toISOString()) : null;
  const from = filters?.from ? dayjs(new Date(filters?.from).toISOString()) : null;
  const typeOptions = Object.keys(Comms.TYPES).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const typeDefaultOption = !Utils.isNull(filters.type)
    ? findOption(typeOptions, String(filters.type)?.toUpperCase())
    : null;

  const priorityOptions = Object.keys(Comms.PRIORITY).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(t),
  }));
  const priorityDefaultOption = !Utils.isNull(filters.priority)
    ? findOption(priorityOptions, String(filters.priority)?.toUpperCase())
    : null;

  const statusOptions = Object.keys(Comms.STATUS).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const statusDefaultOption = !Utils.isNull(filters.status)
    ? findOption(statusOptions, String(filters.status)?.toUpperCase())
    : null;

  const tagsOptions = Object.keys(Comms.TAGS).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const tagsDefaultOption = !Utils.isNull(filters.tags)
    ? findOption(tagsOptions, filters.tags)
    : null;

  const handleDateChange = (field, value) => {
    if (Utils.isNull(value)) {
      handleFilterChange(field, null);
      return;
    }

    const v = new Date(value);
    field === "from" ? v.setHours(0, 0, 0) : v.setHours(23, 59, 59);
    handleFilterChange(field, v);
  };

  const handleSearchDebounce = useCallback(
    debounce(async (value) => {
      handleFilterChange("subject", value);
    }, 500),
    []
  );

  async function handleChange(e) {
    setSubject(e.target.value);
    handleSearchDebounce(e.target.value);
  }

  return (
    <div className='filters'>
      <Accordion defaultExpanded={false}>
        <AccordionSummary>
          <div className='title'>
            <div className='flex items-center flex-row gap-2'>
              <FontAwesomeIcon icon={faFilter} />
              Filters
            </div>
            <div className='filter-actions'>
              <div className='flex items-center gap-2 z-20' onClick={handleClearFilters}>
                <FontAwesomeIcon icon={faFilterCircleXmark} />
                Clear Filters
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2'>
            <div>
              <FormInput
                type='text'
                label='Subject'
                classes='w-full'
                placeholder='Subject contains keyword..'
                value={subject}
                handleChange={(e) => handleChange(e)}
              />
            </div>
            <div>
              <FormSelect
                label='Type'
                classes='w-full'
                isClearable={true}
                options={typeOptions}
                value={typeDefaultOption}
                placeholder='Type'
                handleChange={(option) => handleFilterChange("type", option)}
              />
            </div>
            <div>
              <FormSelect
                label='Priority'
                classes='w-full'
                isClearable={true}
                options={priorityOptions}
                value={priorityDefaultOption}
                placeholder='Type'
                handleChange={(option) => handleFilterChange("priority", option)}
              />
            </div>
            <div>
              <FormSelect
                label='Status'
                classes='w-full'
                isClearable={true}
                options={statusOptions}
                value={statusDefaultOption}
                placeholder='Type'
                handleChange={(option) => handleFilterChange("status", option)}
              />
            </div>
            <div>
              <FormSelect
                label='Tags'
                isMulti={true}
                classes='w-full'
                placeholder='Tags'
                isClearable={true}
                options={tagsOptions}
                value={tagsDefaultOption}
                handleChange={(option) => handleFilterChange("tags", option)}
              />
            </div>
            <div>
              <FormDatepicker
                label='From'
                value={from}
                isClearable={true}
                handleChange={(value) => handleDateChange("from", value)}
              />
            </div>
            <div>
              <FormDatepicker
                label='To'
                value={to}
                isClearable={true}
                handleChange={(value) => handleDateChange("to", value)}
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default Filters;
