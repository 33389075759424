import Parse from "parse";
import dayjs from "dayjs";
import { useFormik } from "formik";
import "../../../styles/global.css";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import Discount from "../../../models/Discount";
import { findOption } from "../../../utils/Forms";
import { date, number, object, string } from "yup";
import useToaster from "../../../hooks/useToaster";
import Button from "../../../components/form/Button";
import FormInput from "../../../components/form/FormInput";
import FormSelect from "../../../components/form/FormSelect";
import { userSerializer } from "../../../models/serializers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormDatepicker from "../../../components/form/FormDatepicker";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

const AddUserDiscountsModal = ({
  users,
  isOpen,
  isLoading,
  toggleModal,
  setSelection,
  handleAddDiscounts,
  handleRemoveRecipient,
}) => {
  const toaster = useToaster();
  const [addDiscountsSuccess, setAddDiscountsSucces] = useState(false);

  const validationSchema = object().shape({
    type: string().required("Type cannot be undefined"),
    amount: number().required("Amount cannot be empty"),
    expiresAt: date().nullable(),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      type: "",
      amount: "",
      expiresAt: null,
    },
  });

  const typeOptions = Object.keys(Discount.TYPE).map((key) => ({
    value: key,
    label: Utils.textFirstOnlyUpper(key),
  }));

  const handleSubmit = () => {
    Object.keys(formik.values).forEach((key) => formik.setFieldTouched(key));
    try {
      if (formik.isValid) {
        handleAddDiscounts(users, formik.values).then((r) => {
          if (r) {
            setAddDiscountsSucces(true);
            setTimeout(() => {
              formik.resetForm();
              toggleModal(false);
              setSelection && setSelection([]);
              setAddDiscountsSucces(false);
            }, 1500);
          } else {
            setAddDiscountsSucces(false);
          }
        });
      }
    } catch (e) {
      setAddDiscountsSucces(false);
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleCancel = () => {
    formik.resetForm();
    toggleModal(false);
  };

  const getUserData = (userId) => {
    return userSerializer(new Parse.Object("_User").set("objectId", userId));
  };

  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <Modal
      open={isOpen}
      title='Add Discount'
      classes={"w-4/5 md:w-1/2 xl:w-1/3"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div className='grid grid-cols-1 gap-1'>
        {!!users && (
          <>
            <div className='mb-2'>
              <span className='text-text-main'>Recepients:</span>
            </div>
            <div className='flex flex-wrap items-center gap-1 mb-1 p-1 max-h-20 overflow-scroll bg-bg-input rounded border border-solid border-border-input'>
              {users.map((id) => {
                const user = getUserData(id);
                return (
                  <div
                    key={id}
                    className='flex items-center rounded-full bg-gray-100 bg-opacity-70 px-2 py-1 transition-all ease-in-out duration-200 hover:bg-blue-100 hover:bg-opacity-50'
                  >
                    <span className='text-sm'>{user.username}</span>
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={`ml-1 w-[10px] h-[10px] ${
                        handleRemoveRecipient ? "cursor-pointer" : "cursor-not-allowed"
                      } p-1 rounded-full hover:bg-white`}
                      onClick={() => (handleRemoveRecipient ? handleRemoveRecipient(id) : null)}
                    />
                  </div>
                );
              })}
            </div>
          </>
        )}
        <FormInput
          required
          type='number'
          label='Amount'
          value={formik.values.amount}
          errors={formik.touched.amount && formik.errors.amount}
          handleChange={(e) => {
            formik.setFieldTouched("amount");
            formik.setFieldValue("amount", e.target.value);
          }}
        />
        <FormSelect
          label='Type'
          value={
            !Utils.isNull(formik.values.type) ? findOption(typeOptions, formik.values.type) : null
          }
          options={typeOptions}
          errors={formik.touched.type && formik.errors.type}
          handleChange={(value) => {
            formik.setFieldTouched("type");
            formik.setFieldValue("type", value.value);
          }}
        />
        <FormDatepicker
          withTime={true}
          isClearable={false}
          label='Discount expiration date'
          disablePastDates={true}
          value={dayjs(formik.values.expiresAt)}
          errors={formik.touched.expiresAt && formik.errors.expiresAt}
          handleChange={(value) => {
            formik.setFieldTouched("expiresAt");
            formik.setFieldValue("expiresAt", value);
          }}
        />
        <div className='grid grid-cols-2 gap-2 mt-4'>
          <Button classes='bg-gray-200 py-2' isLoading={isLoading} handleClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button
            isLoading={isLoading}
            handleClick={handleSubmit}
            disabled={isLoading || addDiscountsSuccess}
            classes={`bg-blue-500 py-2 ${isLoading ? "pr-7" : "pr-2"}`}
          >
            <span className='text-white'>Add</span>
            {addDiscountsSuccess && (
              <FontAwesomeIcon icon={faCheckCircle} className='ml-2 text-white' />
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddUserDiscountsModal;
