import Parse from "parse";
import dayjs from "dayjs";
import "../styles/styles.scss";
import { useState, useEffect } from "react";
import { metersToKM } from "../../../utils/Units";
import DashboardPageView from "../view/DashboardPageView";
import { secondsToDaysHoursMinutes } from "../../../utils/Dates";

const DashboardPageState = () => {
  const [userTotals, setUserTotals] = useState(null);
  const [rideTotals, setRideTotals] = useState(null);
  const [mostRidedCars, setMostRidedCars] = useState(null);
  const [rentingTotals, setRentingTotals] = useState(null);
  const [revenueTotals, setRevenueTotals] = useState(null);
  const [mostRidingUsers, setMostRidingUsers] = useState(null);
  const [ageDemographics, setAgeDemographics] = useState(null);
  const [genderDemographics, setGenderDemographics] = useState(null);

  const getUserTotals = async () => {
    try {
      const totalUsers = await new Parse.Query("_User").count(null, {
        useMasterKey: true,
      });
      const totalPending = await new Parse.Query("_User")
        .equalTo("isAuthorized", false)
        .equalTo("isRejected", false)
        .count(null, {
          useMasterKey: true,
        });
      const totalAuthorized = await new Parse.Query("_User")
        .equalTo("isAuthorized", true)
        .equalTo("isRejected", false)
        .count(null, {
          useMasterKey: true,
        });
      const totalUnauthorized = await new Parse.Query("_User")
        .equalTo("isAuthorized", false)
        .equalTo("isRejected", true)
        .count(null, {
          useMasterKey: true,
        });
      const totalRejected = await new Parse.Query("_User")
        .equalTo("isAuthorized", true)
        .equalTo("isRejected", true)
        .count(null, {
          useMasterKey: true,
        });
      const totalLocked = await new Parse.Query("_User").equalTo("isLocked", true).count(null, {
        useMasterKey: true,
      });
      const totalReferred = await new Parse.Query("_User").exists("referredBy").count(null, {
        useMasterKey: true,
      });
      setUserTotals({
        totalUsers: totalUsers,
        totalPending: totalPending,
        totalAuthorized: totalAuthorized,
        totalUnauthorized: totalUnauthorized,
        totalRejected: totalRejected,
        totalLocked: totalLocked,
        totalReferred: totalReferred,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getRentingTotals = async () => {
    try {
      const totalRentings = await new Parse.Query("Rentings").count(null, {
        useMasterKey: true,
      });
      const totalActive = await new Parse.Query("Rentings").doesNotExist("endTime").count(null, {
        useMasterKey: true,
      });
      const totalUnpaid = await new Parse.Query("Rentings").equalTo("isPaid", false).count(null, {
        useMasterKey: true,
      });
      const totalNoPhotos = await new Parse.Query("Rentings").doesNotExist("carFront").count(null, {
        useMasterKey: true,
      });
      const totalPhotos = await new Parse.Query("Rentings").equalTo("status", 1).count(null, {
        useMasterKey: true,
      });
      const totalRatings = await new Parse.Query("Rentings").equalTo("status", 2).count(null, {
        useMasterKey: true,
      });
      const totalSplitBill = await new Parse.Query("Rentings")
        .equalTo("isSplitBilling", true)
        .count(null, {
          useMasterKey: true,
        });
      setRentingTotals({
        totalRentings: totalRentings,
        totalActive: totalActive,
        totalUnpaid: totalUnpaid,
        totalNoPhotos: totalNoPhotos,
        totalPhotos: totalPhotos,
        totalRatings: totalRatings,
        totalSplitBill: totalSplitBill,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getRideTotals = async () => {
    try {
      const totalTimeInSeconds = (
        await new Parse.Query("Rentings").aggregate({
          group: {
            objectId: null,
            total: { $sum: "$totalTime" },
          },
        })
      )[0].total;
      const totalDistance = (
        await new Parse.Query("Rentings").aggregate({
          group: {
            objectId: null,
            total: { $sum: "$distance" },
          },
        })
      )[0].total;
      const totalUnique = await new Parse.Query("Rentings").distinct("driver");

      setRideTotals({
        totalTime: secondsToDaysHoursMinutes(totalTimeInSeconds),
        totalDistance: metersToKM(totalDistance),
        totalUnique: totalUnique.length,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getRevenueTotals = async () => {
    try {
      const totalBill = (
        await new Parse.Query("Rentings").aggregate({
          group: {
            objectId: null,
            total: { $sum: "$bill" },
          },
        })
      )[0].total;
      const totalUnpaid = (
        await new Parse.Query("Rentings").aggregate({
          match: { isPaid: false },
          group: {
            objectId: null,
            total: { $sum: "$bill" },
          },
        })
      )[0].total;
      const totalPaid = (
        await new Parse.Query("Rentings").aggregate({
          match: { isPaid: true },
          group: {
            objectId: null,
            total: { $sum: "$bill" },
          },
        })
      )[0].total;

      setRevenueTotals({
        totalBill: parseFloat(totalBill).toFixed(2),
        totalUnpaid: parseFloat(totalUnpaid).toFixed(2),
        totalPaid: parseFloat(totalPaid).toFixed(2),
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getMostRidingUsers = async () => {
    // Sum total rides per user
    const total = await new Parse.Query("Rentings").aggregate({
      addFields: {
        userPointer: { $substr: ["$_p_driver", 6, -1] }, // Remove Car$
      },
      lookup: {
        from: "_User", // The MongoDB collection name for the Car class
        localField: "userPointer", // The field in Rentings that points to Car
        foreignField: "_id", // The corresponding field in Car (MongoDB ObjectId)
        as: "details",
      },
      unwind: "$details",
      match: {
        "details.username": { $exists: true }, // Exclude cars with inversQNR = "ARISTEST"
      },
      group: {
        objectId: "$driver",
        totalCount: { $sum: 1 },
      },
      sort: { totalCount: -1 }, // Optional: Sort by count in descending order
      limit: 30,
    });

    const userData = await Promise.all(
      total.map(
        async (i) =>
          await new Parse.Query("_User")
            .equalTo("objectId", i.objectId)
            .first({ useMasterKey: true })
      )
    );

    const finalData = total.map((i) => {
      const user = userData.find((j) => j.id === i.objectId);
      return {
        id: i.objectId,
        count: i.totalCount,
        user: { ...user.attributes },
      };
    });

    setMostRidingUsers(finalData);
  };

  const getMostRidedCars = async () => {
    // Sum total rides per car
    const total = await new Parse.Query("Rentings").aggregate({
      // addFields: {
      //   carPointer: { $substr: ["$_p_car", 4, -1] }, // Remove Car$
      // },
      // lookup: {
      //   from: "Car", // The MongoDB collection name for the Car class
      //   localField: "carPointer", // The field in Rentings that points to Car
      //   foreignField: "_id", // The corresponding field in Car (MongoDB ObjectId)
      //   as: "details",
      // },
      // unwind: "$details",
      // match: {
      //   "details.inversQNR": { $ne: "ARISTEST" }, // Exclude cars with inversQNR = "ARISTEST"
      // },
      group: {
        objectId: "$car",
        totalCount: { $sum: 1 },
      },
      sort: { totalCount: -1 }, // Optional: Sort by count in descending order
      limit: 30,
    });

    const carData = await Promise.all(
      total.map(async (i) => await new Parse.Query("Car").get(i.objectId))
    );

    const finalData = total.map((i) => {
      const car = carData.find((j) => j.id === i.objectId);
      return { car: { ...car.attributes }, id: i.objectId, count: i.totalCount };
    });

    setMostRidedCars(finalData);
  };

  const getUserGenderDemographics = async () => {
    const males = await new Parse.Query("_User")
      .equalTo("gender", "male")
      .count({ useMasterKey: true });
    const females = await new Parse.Query("_User")
      .equalTo("gender", "female")
      .count({ useMasterKey: true });
    const other = await new Parse.Query("_User")
      .equalTo("gender", "other")
      .count({ useMasterKey: true });

    setGenderDemographics({
      male: males,
      female: females,
      other: other,
      total: males + females + other,
    });
  };

  const getUserAgeDemographics = async () => {
    // Users from 21 to 25
    const group1 = await new Parse.Query("_User")
      .lessThanOrEqualTo("dateOfBirth", dayjs().startOf("day").subtract(21, "year").toDate())
      .greaterThanOrEqualTo(
        "dateOfBirth",
        dayjs()
          .endOf("day")
          .subtract(25 + 1, "year")
          .add(1, "day")
          .toDate()
      )
      .count({ useMasterKey: true });

    // Users from 26 to 30
    const group2 = await new Parse.Query("_User")
      .lessThanOrEqualTo("dateOfBirth", dayjs().startOf("day").subtract(26, "year").toDate())
      .greaterThanOrEqualTo(
        "dateOfBirth",
        dayjs()
          .endOf("day")
          .subtract(30 + 1, "year")
          .add(1, "day")
          .toDate()
      )
      .count({ useMasterKey: true });

    // Users from 31 to 45
    const group3 = await new Parse.Query("_User")
      .lessThanOrEqualTo("dateOfBirth", dayjs().startOf("day").subtract(31, "year").toDate())
      .greaterThanOrEqualTo(
        "dateOfBirth",
        dayjs()
          .endOf("day")
          .subtract(45 + 1, "year")
          .add(1, "day")
          .toDate()
      )
      .count({ useMasterKey: true });

    // Users from 46 to 60
    const group4 = await new Parse.Query("_User")
      .lessThanOrEqualTo("dateOfBirth", dayjs().startOf("day").subtract(46, "year").toDate())
      .greaterThanOrEqualTo(
        "dateOfBirth",
        dayjs()
          .endOf("day")
          .subtract(60 + 1, "year")
          .add(1, "day")
          .toDate()
      )
      .count({ useMasterKey: true });

    // Users from 61+
    const group5 = await new Parse.Query("_User")
      .lessThanOrEqualTo("dateOfBirth", dayjs().startOf("day").subtract(61, "year").toDate())
      .count({ useMasterKey: true });

    setAgeDemographics({
      from21to25: group1,
      from26to30: group2,
      from31to45: group3,
      from46to60: group4,
      from61: group5,
      total: group1 + group2 + group3 + group4 + group5,
    });
  };

  useEffect(() => {
    getUserTotals();
    getRentingTotals();
    getRideTotals();
    getRevenueTotals();
    getMostRidingUsers();
    getMostRidedCars();
    getUserGenderDemographics();
    getUserAgeDemographics();
  }, []);

  return (
    <DashboardPageView
      userTotals={userTotals}
      rideTotals={rideTotals}
      rentingTotals={rentingTotals}
      revenueTotals={revenueTotals}
      mostRidedCars={mostRidedCars}
      mostRidingUsers={mostRidingUsers}
      ageDemographics={ageDemographics}
      genderDemographics={genderDemographics}
    />
  );
};

export default DashboardPageState;
