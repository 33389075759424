import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import Loader from "../../../components/Loader";
import Discount from "../../../models/Discount";
import { dateFormatter } from "../../../utils/Dates";
import { getViewportWidth } from "../../../utils/Viewport";
import { discountSerializer } from "../../../models/serializers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { SortingState, PagingState, CustomPaging } from "@devexpress/dx-react-grid";
import { Grid, Table, PagingPanel, TableHeaderRow } from "@devexpress/dx-react-grid-material-ui";

const DiscountsTable = ({
  sorting,
  discounts,
  isLoading,
  pagination,
  totalElements,
  handleSorting,
  isExternalPage,
  handlePageChange,
  handlePageSizeChange,
}) => {
  const windowSize = getViewportWidth();
  const sortableColumns = ["user", "amount", "type", "addedBy", "createdAt"];

  const TableHeaderRowSortLabelComponent = (props) => {
    if (sortableColumns.includes(props.column.name)) {
      return (
        <TableHeaderRow.SortLabel {...props} className='sortable-column-label'>
          {props.children}
        </TableHeaderRow.SortLabel>
      );
    } else {
      return <span className='font-bold'>{props.column.title}</span>;
    }
  };

  const resolveUsername = (data) => {
    return (
      <Link className='link' to={`/users/${data?.attributes?.user?.id}`}>
        {data?.attributes?.user?.attributes?.username}
      </Link>
    );
  };

  const resolveRenting = (data) => {
    return (
      <Link className='link' to={`/rentings/${data?.attributes?.renting?.id}`}>
        {data?.attributes?.renting?.id}
      </Link>
    );
  };

  const resolveAddedBy = (data) => {
    return (
      <div>
        <div>{data.attributes.addedBy}</div>
        {!!data.attributes.renting && (
          <div className='mt-1'>
            <div>
              <span className='font-bold'>Restricted to:</span>
            </div>
            <div>{resolveRenting(data)}</div>
          </div>
        )}
      </div>
    );
  };

  const resolveIsUsed = (data) => {
    const isUsed = data.attributes?.isUsed;
    return (
      <>
        {isUsed && (
          <Tooltip title='Discount is used' placement='top'>
            <FontAwesomeIcon
              size='lg'
              icon={faCircleCheck}
              className='text-green-500 cursor-pointer'
            />
          </Tooltip>
        )}
      </>
    );
  };

  const resolveDiscount = (data) => {
    const discount = discountSerializer(data);
    return (
      <div className='flex items-center justify-center gap-1'>
        <span className={`px-1 py-0.5 rounded`}>
          {discount.amount} {Discount.getAmountSymbol(discount.type)}
        </span>
        {resolveIsUsed(data)}
      </div>
    );
  };

  const resolveDiscountDates = (data) => {
    let isExpired = false;
    if (!!data.attributes.expiresAt) {
      const now = dayjs();
      const expires = dayjs(data.attributes.expiresAt);
      if (now.isAfter(expires)) {
        isExpired = true;
      }
    }

    return (
      <div>
        <div>{dateFormatter(data.attributes.createdAt, true)}</div>
        {!!data.attributes.expiresAt && (
          <div className='mt-1'>
            <div>
              <span className='font-bold'>Expires at:</span>
            </div>
            <div className={`${isExpired ? "text-red-500" : ""}`}>
              {dateFormatter(data.attributes.expiresAt, true)}
            </div>
          </div>
        )}
      </div>
    );
  };

  const resolveColumns = () => {
    let columns = [
      {
        name: "user",
        title: "User",
        getCellValue: resolveUsername,
      },
    ];

    switch (true) {
      case windowSize === "MOBILE":
        return columns;
      default:
        return [
          {
            name: "user",
            title: "User",
            getCellValue: resolveUsername,
          },
          {
            name: "discount",
            title: "Discount",
            getCellValue: resolveDiscount,
          },
          {
            name: "addedBy",
            title: "Added By",
            getCellValue: resolveAddedBy,
          },
          {
            name: "createdAt",
            title: "Created At",
            getCellValue: resolveDiscountDates,
          },
        ];
    }
  };

  const columns = resolveColumns();

  const cellComponent = (props) => {
    if (windowSize === "MOBILE") {
      const discount = props.row;
      const discountSerialized = discountSerializer(discount);

      return (
        <Table.Cell {...props}>
          <div className='relative'>
            <div className='flex gap-2 items-center'>
              <span className='font-bold'>Discount:</span>
              {resolveDiscount(discount)}
            </div>
            <div className='shrink-0'>
              <div>
                <span className='font-bold'>To:</span> {resolveUsername(discount)}
              </div>
            </div>
            <div>
              <span className='font-bold'>Added by:</span> {discountSerialized.addedBy}
            </div>
            <div className='mt-2'>
              <span className='font-bold'>Created at:</span> {resolveDiscountDates(discount)}
            </div>
          </div>
        </Table.Cell>
      );
    } else {
      return <Table.Cell {...props} />;
    }
  };

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <Grid rows={discounts} columns={columns} getRowId={(row) => row.id}>
        <SortingState sorting={sorting} onSortingChange={handleSorting} />
        <PagingState
          currentPage={pagination.page}
          pageSize={pagination.size}
          onCurrentPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
        />
        <CustomPaging totalCount={totalElements} />
        <Table
          columnExtensions={[
            {
              columnName: "user",
              wordWrapEnabled: true,
            },
            {
              columnName: "discount",
              wordWrapEnabled: true,
              width: "100px",
              align: "center",
            },
            {
              columnName: "addedBy",
              wordWrapEnabled: true,
              width: "200px",
              align: "center",
            },
            {
              columnName: "createdAt",
              wordWrapEnabled: true,
              width: "140px",
            },
            {
              columnName: "expiresAt",
              wordWrapEnabled: true,
              width: "140px",
            },
            {
              columnName: "discount",
              wordWrapEnabled: true,
              width: windowSize === "MOBILE" ? "auto" : "180px",
              align: windowSize === "MOBILE" ? "left" : "center",
            },
          ]}
          cellComponent={cellComponent}
        />
        <TableHeaderRow
          sortLabelComponent={TableHeaderRowSortLabelComponent}
          showSortingControls={true}
        />
        <PagingPanel pageSizes={[...(isExternalPage ? [3, 5] : []), 10, 25, 50, 100, 1000]} />
      </Grid>
    </div>
  );
};

export default DiscountsTable;
