import Parse from "parse";
import dayjs from "dayjs";
import { isNumber } from "lodash";
import { useFormik } from "formik";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import Modal from "../../../components/Modal";
import Discount from "../../../models/Discount";
import { findOption } from "../../../utils/Forms";
import useToaster from "../../../hooks/useToaster";
import Button from "../../../components/form/Button";
import { date, number, object, ref, string } from "yup";
import FormInput from "../../../components/form/FormInput";
import FormSelect from "../../../components/form/FormSelect";
import FormTextarea from "../../../components/form/FormTextarea";
import FormDatepicker from "../../../components/form/FormDatepicker";

const AddCampaignModal = ({ isOpen, toggleModal, emitCampaign }) => {
  const userHook = useUser();
  const toaster = useToaster();
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(false);
  const [addCampaignSuccess, setAddCampaignSucces] = useState(false);

  const [responseState, setResponseState] = useState({
    type: null,
    message: null,
  });

  const testValidationDates = (context) => {
    const end = context.parent.end ? context.parent.end : null;
    const start = context.parent.start ? context.parent.start : null;
    const discountExpiry = context.parent.discountExpiry ? context.parent.discountExpiry : null;

    if (
      !!start &&
      !!end &&
      (dayjs(discountExpiry).isBefore(dayjs(start)) || dayjs(discountExpiry).isBefore(dayjs(end)))
    ) {
      return false;
    } else if (!!start && dayjs(discountExpiry).isBefore(dayjs(start))) {
      return false;
    } else if (!!end && dayjs(discountExpiry).isBefore(dayjs(end))) {
      return false;
    } else {
      return true;
    }
  };

  const validationSchema = object().shape({
    name: string().required("Campaign name is required"),
    discountType: string().required("Discount type is required"),
    discountAmount: number()
      .required("Discount amount is required")
      .typeError("Discount amount must be a number"),
    start: date()
      .required("Start date is required")
      .max(ref("end"), "Start date cannot be after end date"),
    end: date()
      .required("End date is required")
      .min(ref("start"), "End date cannot be before start date"),
    discountExpiry: date()
      .nullable()
      .test("validDates", "Please select a valid date", (value, context) => {
        return testValidationDates(context);
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      name: "",
      comments: "",
      discountType: "",
      discountAmount: "",
      start: null,
      end: null,
      discountExpiry: null,
    },
  });

  const discountTypeOptions = Object.keys(Discount.TYPE)
    .filter((d) => d !== Discount.TYPE.RIDE)
    .filter((d) => d !== Discount.TYPE.REFERRAL)
    .map((key) => ({
      value: key,
      label: Utils.textFirstOnlyUpper(key),
    }));
  const discountTypeDfaultOption = !Utils.isNull(formik.values.discountType)
    ? findOption(discountTypeOptions, formik.values.discountType)
    : null;

  const handleSetFieldValue = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleSubmit = async () => {
    Object.keys(formik.values).forEach((k) => formik.setFieldTouched(k));

    try {
      if (formik.isValid) {
        setLoading(true);
        const Campaign = new Parse.Object("Campaign");
        Campaign.set("code", formik.values.name);
        Campaign.set("discountAmount", Number(formik.values.discountAmount));
        Campaign.set("discountType", formik.values.discountType);
        Campaign.set("start", formik.values.start);
        Campaign.set("end", formik.values.end);
        !!formik.values.comments && Campaign.set("comments", formik.values.comments);
        !!formik.values.discountExpiry &&
          Campaign.set("discountExpiry", formik.values.discountExpiry);
        await Campaign.save(null, { useMasterKey: true }).then(async (r) => {
          if (r) {
            setAddCampaignSucces(true);
            setResponseState({
              type: true,
              message: "Campaign added successfully.",
            });
            emitCampaign(r);
            setTimeout(() => {
              formik.handleReset();
              toggleModal(false);
              setAddCampaignSucces(false);
            }, 1500);
            await Logger.addCampaign(session, r);
          } else {
            setAddCampaignSucces(false);
            setResponseState({
              type: false,
              message: "Something went wrong. Try again.",
            });
          }
          setLoading(false);
        });
      }
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    toggleModal(false);
    formik.handleReset();
    setAddCampaignSucces(false);
    setResponseState({ type: null, message: null });
  };

  useEffect(() => {
    formik.validateForm();
  }, []);

  return (
    <>
      <Modal
        open={isOpen}
        title='Add Campaign'
        classes={"w-11/12 md:w-2/3 xl:w-5/12"}
        handleToggle={() => toggleModal(!isOpen)}
      >
        <div>
          <div className='grid grid-cols-1 gap-2'>
            <div className='grid grid-cols-2 gap-2 items-start'>
              <div className='col-span-1'>
                <FormInput
                  type='text'
                  label='Campaign Code'
                  value={formik.values.name}
                  placeholder='Campaign Code'
                  errors={formik.touched.name && formik.errors.name}
                  handleChange={(e) => handleSetFieldValue("name", e.target.value)}
                />
              </div>
              <div className='col-span-1'>
                <FormSelect
                  label='Discount Type'
                  options={discountTypeOptions}
                  value={discountTypeDfaultOption}
                  errors={formik.touched.discountType && formik.errors.discountType}
                  handleChange={(value) => handleSetFieldValue("discountType", value.value)}
                />
              </div>
            </div>
            <div className='grid grid-cols-2 gap-2 items-start'>
              <div className='col-span-1'>
                <FormInput
                  type='text'
                  label='Discount Amount'
                  placeholder='Discount Amount'
                  value={formik.values.discountAmount}
                  errors={formik.touched.discountAmount && formik.errors.discountAmount}
                  handleChange={(e) =>
                    handleSetFieldValue(
                      "discountAmount",
                      isNumber(e.target.value) ? Number(e.target.value) : e.target.value
                    )
                  }
                />
              </div>
              <div className='col-span-2 sm:col-span-1'>
                <FormDatepicker
                  withTime={true}
                  isClearable={false}
                  disablePastDates={true}
                  label='Discount Expiration Date'
                  value={dayjs(formik.values.discountExpiry)}
                  errors={formik.touched.discountExpiry && formik.errors.discountExpiry}
                  handleChange={(value) => handleSetFieldValue("discountExpiry", value)}
                />
              </div>
            </div>
            <div className='grid grid-cols-2 gap-2 items-start'>
              <div className='col-span-2 sm:col-span-1'>
                <FormDatepicker
                  withTime={true}
                  label='Start Date'
                  isClearable={false}
                  value={dayjs(formik.values.start)}
                  errors={formik.touched.start && formik.errors.start}
                  handleChange={(value) => handleSetFieldValue("start", value)}
                />
              </div>
              <div className='col-span-2 sm:col-span-1'>
                <FormDatepicker
                  withTime={true}
                  label='End Date'
                  isClearable={false}
                  value={dayjs(formik.values.end)}
                  errors={formik.touched.end && formik.errors.end}
                  handleChange={(value) => handleSetFieldValue("end", value)}
                />
              </div>
            </div>
            <FormTextarea
              rows='3'
              label='Comments'
              value={formik.values.comments}
              placeholder='Add comments for the campaign here..'
              errors={formik.touched.comments && formik.errors.comments}
              handleChange={(value) => handleSetFieldValue("comments", value)}
            />
          </div>
          <div className='grid grid-cols-2 gap-2 mt-4'>
            <Button classes='bg-gray-200 py-2' disabled={isLoading} handleClick={handleCancel}>
              <span>Cancel</span>
            </Button>
            <Button
              isLoading={isLoading}
              handleClick={handleSubmit}
              classes={`bg-blue-500 py-2`}
              success={addCampaignSuccess}
              disabled={isLoading || addCampaignSuccess}
            >
              <span className='text-white'>Add</span>
            </Button>
          </div>
          {!Utils.isNull(responseState.type) && (
            <div className='mt-1'>
              <span
                className={`text-sm font-bold ${
                  responseState.type ? "text-green-400" : "text-red-400"
                }`}
              >
                {responseState.message}
              </span>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default AddCampaignModal;
