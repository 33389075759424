import Parse from "parse";
import "./styles/Header.scss";
import Toggler from "./Toggler";
import User from "../models/User";
import Utils from "../utils/Utils";
import useUser from "../hooks/useUser";
import { Tooltip } from "@mui/material";
import { NavLink } from "react-router-dom";
import useStorage from "../hooks/useStorage";
import { useEffect, useRef, useState } from "react";
import NotificationsPanel from "./NotificationsPanel";
import { useDispatch, useSelector } from "react-redux";
import { ACTIONS, THEMES } from "../store/actions/theme";
import LogoDark from "./../assets/images/caroo_logo-dark.svg";
import LogoLight from "./../assets/images/caroo_logo-light.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faUser } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const userHook = useUser();
  const repeater = useRef(null);
  const dispatch = useDispatch();
  const userRoles = userHook.getUserRoles();
  const session = userHook.getCurrentSession();
  const userStorage = useStorage("user-preferences");
  const [isUserMenuOpen, setUserMenuOpen] = useState(false);
  const [unseenNotifsCount, setUnseenNotifsCount] = useState(0);
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isNotifsPanelOpen, setNotifsPanelOpen] = useState(false);
  const [showNotificationIndicator, setShowNotificationIndicator] = useState(false);
  const Logo = useSelector((state) => state?.theme) === THEMES.DARK ? LogoDark : LogoLight;
  const [isDarkTheme, setDarkTheme] = useState(userStorage.read("theme") === THEMES.DARK ?? false);

  const adminAccess =
    userRoles?.includes(User.ROLES.ADMIN) || userRoles?.includes(User.ROLES.VIEWER);

  const handleThemeChange = (isDark) => {
    if (isDark) {
      userStorage.add({ theme: THEMES.DARK });
      dispatch(ACTIONS.setTheme(THEMES.DARK));
      setDarkTheme(true);
    } else {
      userStorage.add({ theme: THEMES.LIGHT });
      dispatch(ACTIONS.setTheme(THEMES.LIGHT));
      setDarkTheme(false);
    }
  };

  const countUnseenNotifs = async () => {
    if (!!!session) return;
    const userId = session?.user.objectId;
    const count = await new Parse.Query("Notifications")
      .notContainedIn("isSeen", [userId])
      .count({ useMasterKey: true });

    setUnseenNotifsCount(count);

    if (count > 0) setShowNotificationIndicator(true);
    else setShowNotificationIndicator(false);
  };

  const handleLogout = async (isMobile = false) => {
    try {
      if (isMobile) setMobileMenuOpen(!isMobileMenuOpen);
      await userHook.logout();
    } catch (error) {
      console.error(`Error! ${error.message}`);
    }
  };

  useEffect(() => {
    countUnseenNotifs();
    repeater.current = setInterval(() => {
      countUnseenNotifs();
    }, 120 * 1000);

    return () => {
      clearInterval(repeater.current);
    };
  }, []);

  return (
    <div className='header'>
      <div className='wrapper'>
        <div className='flex gap-14 items-center'>
          <div className='logo'>
            <img src={Logo} alt='Caroo-logo' />
          </div>

          <div className='hidden lg:flex gap-2 items-center justify-end'>
            {adminAccess && (
              <div className='flex items-center'>
                <div className='menu'>
                  {adminAccess && (
                    <>
                      <NavLink className='item' to='/'>
                        <span>Dashboard</span>
                      </NavLink>
                      <NavLink className='item' to='/users'>
                        <span>Users</span>
                      </NavLink>
                      <NavLink className='item' to='/rentings'>
                        <span>Rentings</span>
                      </NavLink>
                      <NavLink className='item' to='/cars'>
                        <span>Cars</span>
                      </NavLink>
                      <div className='item'>
                        <span>Other</span>
                        <div className='submenu'>
                          <NavLink to='/discounts'>Discounts</NavLink>
                          <NavLink to='/user-messages'>User Messages</NavLink>
                          <NavLink to='/communication-history'>Comms History</NavLink>
                          <NavLink to='/maintenances'>Maintenances</NavLink>
                          <NavLink to='/Incidents'>Incidents</NavLink>
                          <NavLink to='/damages'>Damages</NavLink>
                          <NavLink to='/logs'>Logs</NavLink>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='justify-end'>
          {!Utils.isNull(userHook.getCurrentSession()) ? (
            <div className='flex items-center gap-2'>
              <div className='relative'>
                <div
                  className='header-icon-button flex relative'
                  onClick={() => {
                    setUserMenuOpen(false);
                    setNotifsPanelOpen(!isNotifsPanelOpen);
                  }}
                >
                  {showNotificationIndicator && (
                    <Tooltip title='There are new users pending for approval'>
                      <div className='absolute top-0 right-0 w-4 h-4 rounded-full bg-red-500 flex items-center justify-center'>
                        <span className='text-white text-xs'>{unseenNotifsCount}</span>
                      </div>
                    </Tooltip>
                  )}
                  <FontAwesomeIcon icon={faBell} className='text-gray-100' />
                </div>
                {isNotifsPanelOpen && (
                  <NotificationsPanel
                    countUnseenNotifs={countUnseenNotifs}
                    setNotifsPanelOpen={setNotifsPanelOpen}
                  />
                )}
              </div>
              <div className='hidden lg:flex relative'>
                <div
                  className='header-icon-button hidden lg:flex'
                  onClick={() => {
                    setNotifsPanelOpen(false);
                    setUserMenuOpen(!isUserMenuOpen);
                  }}
                >
                  <FontAwesomeIcon icon={faUser} className='text-gray-100' />
                </div>
                {isUserMenuOpen && (
                  <div className='user-menu' onMouseLeave={() => setUserMenuOpen(!isUserMenuOpen)}>
                    <div className='item flex flex-row items-center gap-2'>
                      <span>Dark mode</span>
                      <Toggler
                        isBlue={true}
                        toggle={isDarkTheme}
                        handleToggle={(toggle) => handleThemeChange(toggle)}
                      />
                    </div>
                    <NavLink to={`/users/${session.user.objectId}`} className='item hoverable'>
                      <span>My Profile</span>
                    </NavLink>
                    <NavLink to='/configurations/campaigns' className='item hoverable'>
                      <span>Configurations</span>
                    </NavLink>
                    <NavLink to='/support' className='item hoverable'>
                      <span>Support Manual</span>
                    </NavLink>
                    <div className='item hoverable' onClick={() => handleLogout(false)}>
                      <span>Logout</span>
                    </div>
                  </div>
                )}
              </div>
              <div
                className='header-icon-button flex lg:hidden'
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              >
                <FontAwesomeIcon icon={faBars} className='text-gray-100' />
              </div>
            </div>
          ) : (
            <div>
              <NavLink to='/login'>
                <span className='text-text-main text-xl'>Login</span>
              </NavLink>
            </div>
          )}
        </div>
      </div>

      <div className={`mobile-menu ${isMobileMenuOpen ? "opened" : "closed"}`}>
        <div className='px-4 py-2 border-b border-solid border-border-card-main'>
          <span className='text-text-main text-xl font-semibold'>
            <NavLink to={`/users/${session?.user?.objectId}`}>My Profile</NavLink>
          </span>
          <div className='item flex flex-row items-center gap-2 justify-end mt-1'>
            <span>Dark mode</span>
            <Toggler
              isBlue={true}
              toggle={isDarkTheme}
              handleToggle={(toggle) => handleThemeChange(toggle)}
            />
          </div>
        </div>
        <div className='menu-links'>
          {adminAccess && (
            <>
              <NavLink to='/' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span className=''>Dashboard</span>
              </NavLink>
              <NavLink to='/users' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span>Users</span>
              </NavLink>
              <NavLink to='/user-messages' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span>User Messages</span>
              </NavLink>
              <NavLink to='/rentings' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span>Rentings</span>
              </NavLink>
              <NavLink to='/incidents' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span>Incidents</span>
              </NavLink>
              <NavLink to='/cars' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span>Cars</span>
              </NavLink>
              <NavLink to='/discounts' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span>Discounts</span>
              </NavLink>
              <NavLink to='/damages' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span>Damages</span>
              </NavLink>
              <NavLink to='/maintenances' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span>Maintenances</span>
              </NavLink>
              <NavLink to='/logs' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span>Logs</span>
              </NavLink>
              <NavLink
                to='/configurations/general'
                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
              >
                <span>Configurations</span>
              </NavLink>
              <NavLink to='/support' onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
                <span>Support Manual</span>
              </NavLink>
            </>
          )}
        </div>
        <div
          className='text-right cursor-pointer px-4 pt-2 pb-3 border-t border-solid border-border-card-main'
          onClick={() => handleLogout(true)}
        >
          <span className='text-xl'>Logout</span>
        </div>
      </div>
    </div>
  );
};

export default Header;
