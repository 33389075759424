import { THEMES } from "../actions/theme";

// initial state
const initialState = THEMES.LIGHT;

const theme = (state = initialState, action) => {
  if (state?.theme === null) return THEMES.LIGHT;

  switch (action.type) {
    case THEMES.DARK:
      return THEMES.DARK;
    case THEMES.LIGHT:
      return THEMES.LIGHT;
    default:
      return state;
  }
};

export default theme;
