import Parse from "parse";
import useUser from "./useUser";
import Logger from "../models/Logger";
import useToaster from "./useToaster";

const useRenting = () => {
  const userHook = useUser();
  const toaster = useToaster();
  const session = userHook.getCurrentSession();

  const handleNotesSubmit = async (value, rentingId) => {
    const Renting = new Parse.Object("Rentings");
    Renting.set("objectId", rentingId);
    !!value ? Renting.set("notes", value) : Renting.unset("notes");
    try {
      await Renting.save(null, { useMasterKey: true });
      await Logger.addRentingNotes(session, Renting);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  const handleSetIsReviewed = async (value, rentingId) => {
    const Renting = new Parse.Object("Rentings");
    Renting.set("objectId", rentingId);
    Renting.set("isReviewed", value);
    try {
      await Renting.save(null, { useMasterKey: true });
      await Logger.markedRentingReviewed(session, Renting);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  return {
    handleNotesSubmit,
    handleSetIsReviewed,
  };
};

export default useRenting;
