import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { THEMES } from "../../../store/actions/theme";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const GenderDemographics = ({ genderDemographics }) => {
  const THEME = useSelector((state) => state?.theme);

  const chartData = {
    labels: ["Male", "Female", "Other"],
    datasets: [
      {
        data: [
          genderDemographics?.male || 0,
          genderDemographics?.female || 0,
          genderDemographics?.other || 0,
        ],
        backgroundColor: ["#0863bc", "#1f77cc", "#4d9ff0"],
        borderWidth: 0.5,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        labels: {
          color: THEME === THEMES.DARK ? "#fff" : "#000",
          font: {
            size: 14,
          },
        },
      },
      datalabels: {
        color: "#ffffff",
        font: {
          size: 14,
          weight: "bold",
        },
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          const percentage = (
            (value / context.dataset.data.reduce((a, b) => a + b, 0)) *
            100
          ).toFixed(1);
          return `${label}\n${value} (${percentage}%)`;
        },
        anchor: "end",
        align: "start",
        offset: 10,
      },
      anchor: "center",
      align: "center",
      tooltip: {
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        backgroundColor: "#000000",
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  return (
    <div className='dashboard-block'>
      {!!!genderDemographics && <Loader isLoading={true} />}
      <div className='title'>
        <span>User Gender Demographics</span>
      </div>
      <div className='px-0 xl:px-10 2xl:px-20'>
        <Pie data={chartData} options={chartOptions} />
      </div>
      <div className='text-center leading-0 mt-2'>
        <span className='text-sm leading-2'>
          Total: <b>{genderDemographics?.total}</b>
        </span>
        <br />
        <span className='text-sm'>of all users who have defined their gender</span>
      </div>
    </div>
  );
};

export default GenderDemographics;
