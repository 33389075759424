import Parse from "parse";
import Loader from "../../Loader";
import { useEffect, useState } from "react";
import Logger from "../../../models/Logger";
import useUser from "../../../hooks/useUser";
import useToaster from "../../../hooks/useToaster";
import CampaignCardView from "../view/CampaignCardView";

const CampaignCardState = ({ campaign }) => {
  const userHook = useUser();
  const toaster = useToaster();
  const session = userHook.getCurrentSession();
  const [isLoading, setLoading] = useState(false);
  const [campaignState, setCampaignState] = useState(null);
  const [discountsGenerated, setDiscountsGenerated] = useState(null);

  const getDiscountsGenerated = async () => {
    setLoading(true);
    const Discounts = await new Parse.Query("Discount")
      .equalTo("addedBy", campaign.attributes.code)
      .count({ useMasterKey: true });
    setDiscountsGenerated(Discounts);
    setLoading(false);
  };

  const handleSubmit = async (form) => {
    setLoading(true);

    try {
      const Campaign = new Parse.Object("Campaign").set("objectId", campaign.id);
      Campaign.set("end", form.values.end);
      Campaign.set("start", form.values.start);
      Campaign.set("discountType", form.values.discountType);
      Campaign.set("discountAmount", Number(form.values.discountAmount));
      !!form.values.comments && Campaign.set("comments", form.values.comments);
      !!form.values.discountExpiry && Campaign.set("discountExpiry", form.values.discountExpiry);
      await Campaign.save(null, { useMasterKey: true });
      await Logger.editCampaign(session, Campaign);
      setCampaignState(Campaign);
      setLoading(false);
    } catch (e) {
      console.error(e.message);
      toaster.error(e.message);
    }
  };

  useEffect(() => {
    getDiscountsGenerated();
  }, []);

  useEffect(() => {
    setCampaignState(campaign);
  }, [campaign]);

  return (
    <div className='card-wrapper'>
      {!!!campaignState ? (
        <Loader isLoading={isLoading} />
      ) : (
        <CampaignCardView
          isLoading={isLoading}
          campaign={campaignState}
          handleSubmit={handleSubmit}
          discountsGenerated={discountsGenerated}
        />
      )}
    </div>
  );
};

export default CampaignCardState;
