import Parse from "parse";
import Utils from "../../../utils/Utils";
import { useState, useEffect } from "react";
import useUser from "../../../hooks/useUser";
import Loader from "../../../components/Loader";
import DiscountsPageView from "../view/DiscountsPageView";
import { getViewportWidth } from "../../../utils/Viewport";

const DiscountsPageState = ({ userId = null }) => {
  const userHook = useUser();
  const windowSize = getViewportWidth();
  const [filters, setFilters] = useState({});
  const isExternalPage = !Utils.isNull(userId);
  const [discounts, setDiscounts] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [totalElements, setTotalElements] = useState(0);
  const [isLoadingDiscount, setLoadingDiscount] = useState(false);
  const [sorting, setSorting] = useState([{ columnName: "createdAt", direction: "desc" }]);
  const [pagination, setPagination] = useState({
    page: 0,
    size: isExternalPage ? 3 : windowSize === "MOBILE" ? 10 : 25,
  });

  const getDiscounts = async () => {
    const Discounts = Parse.Object.extend("Discount");
    const query = new Parse.Query(Discounts);
    query.include("user");

    if (!!userId) {
      const userPointer = {
        __type: "Pointer",
        className: "_User",
        objectId: userId,
      };
      query.equalTo("user", userPointer);
    }

    try {
      if (Object.keys(filters).length > 0) {
        filters.type && query.equalTo("type", filters.type);
        filters.isUsed && query.equalTo("isUsed", false);
        filters.addedBy && query.matches("addedBy", filters.addedBy, "i");

        if (filters?.username || filters?.lastName) {
          let driver = Parse.Object.extend("_User");
          const q = new Parse.Query(driver);
          filters.username && q.matches("username", filters.username.trim(), "i");
          filters.lastName && q.matches("lastName", filters.lastName.trim(), "i");
          driver = await q.first();
          query.equalTo("user", driver);
        }
        filters.from && query.greaterThanOrEqualTo("createdAt", filters.from);
        filters.to && query.lessThanOrEqualTo("createdAt", filters.to);
      }

      if (sorting[0]?.direction === "asc") {
        query.ascending(sorting[0].columnName);
      } else {
        query.descending(sorting[0].columnName);
      }
      query.limit(pagination.size);
      query.skip(pagination.page * pagination.size);
      query.withCount(true);
      query
        .find()
        .then((r) => {
          setDiscounts(r.results);
          setTotalElements(r.count);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      console.error(e);
    }
  };

  const handleFilterChange = (field, value) => {
    setLoading(true);
    setFilters((prev) => ({ ...prev, [field]: value?.value ?? value }));
    (Utils.isNull(value) || value === "") &&
      setFilters((prev) => {
        const obj = { ...prev };
        delete obj[field];
        return obj;
      });
    setPagination({ ...pagination, page: 0 });
  };

  const handleClearFilters = (e) => {
    setLoading(true);
    e.stopPropagation();
    setFilters({});
  };

  const handleSorting = (sorting) => {
    setLoading(true);
    setSorting(sorting);
  };

  const handlePageChange = (page) => {
    setLoading(true);
    setPagination({ ...pagination, page: page });
  };

  const handlePageSizeChange = (size) => {
    setLoading(true);
    setPagination({ ...pagination, size: size });
  };

  const handleAddDiscount = async (_, form) => {
    setLoading(true);
    setLoadingDiscount(true);
    return await userHook
      .handleAddDiscounts([userId], form)
      .then(() => {
        setLoading(false);
        setLoadingDiscount(false);
        getDiscounts();
        return true;
      })
      .catch((e) => {
        console.error(e.message);
        return false;
      });
  };

  useEffect(() => {
    if (filters?.username?.length > 0 || filters?.lastName?.length > 0) {
      const timer = setTimeout(() => {
        getDiscounts();
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      getDiscounts();
    }
  }, [isLoading, filters, sorting, pagination]);

  return (
    <>
      {discounts ? (
        <DiscountsPageView
          sorting={sorting}
          filters={filters}
          discounts={discounts}
          isLoading={isLoading}
          pagination={pagination}
          totalElements={totalElements}
          handleSorting={handleSorting}
          isExternalPage={isExternalPage}
          handlePageChange={handlePageChange}
          isLoadingDiscount={isLoadingDiscount}
          handleAddDiscount={handleAddDiscount}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handlePageSizeChange={handlePageSizeChange}
        />
      ) : (
        <Loader isLoading={isLoading} />
      )}
    </>
  );
};

export default DiscountsPageState;
