import dayjs from "dayjs";
import Parse from "parse";
import { useFormik } from "formik";
import "../../../styles/global.css";
import { object, string } from "yup";
import Utils from "../../../utils/Utils";
import { useParams } from "react-router-dom";
import Modal from "../../../components/Modal";
import Renting from "../../../models/Renting";
import Comms from "../../../models/Communication";
import { findOption } from "../../../utils/Forms";
import useToaster from "../../../hooks/useToaster";
import { useEffect, useMemo, useState } from "react";
import Button from "../../../components/form/Button";
import FormInput from "../../../components/form/FormInput";
import FormSelect from "../../../components/form/FormSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormTextarea from "../../../components/form/FormTextarea";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import FormDatepicker from "../../../components/form/FormDatepicker";
import FileUploadDropzone from "../../../components/form/FileUploadDropzone";

const CommsModal = ({
  isOpen,
  isLoading,
  editingComm,
  toggleModal,
  setEditingCom,
  handleSubmitCommunication,
}) => {
  const toaster = useToaster();
  const { userId } = useParams();
  const [selectedTags, setSelectedTags] = useState([]);
  const [commSuccess, setCommSuccess] = useState(false);
  const [rentingOptions, setRentingOptions] = useState([]);
  const [rentingDefaultOption, setRentingDefaultOption] = useState(null);

  const validationSchema = object().shape({
    type: string().required("Type is required"),
    status: string().required("Status is required"),
    subject: string().required("Subject is required"),
    content: string().required("Content is required"),
    priority: string().required("Priority is required"),
  });

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      type: null,
      subject: "",
      content: "",
      status: null,
      renting: null,
      followup: null,
      priority: null,
      attachment: null,
      tags: selectedTags,
    },
  });

  const initializeForm = useMemo(() => {
    editingComm && setSelectedTags(editingComm.attributes.tags ?? []);
    return {
      type: editingComm ? editingComm.attributes.type : null,
      subject: editingComm ? editingComm.attributes.subject : "",
      content: editingComm ? editingComm.attributes.content : "",
      status: editingComm ? editingComm.attributes.status : null,
      followup: editingComm?.attributes?.followupDate
        ? dayjs(editingComm.attributes.followupDate)
        : null,
      priority: editingComm ? editingComm.attributes.priority : null,
      tags: editingComm ? editingComm.attributes.tags : selectedTags,
      attachment: null,
      renting: editingComm ? editingComm.attributes.renting?.id : null,
    };
  }, [editingComm]);

  const typeOptions = Object.keys(Comms.TYPES).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const typeDefaultOption = !Utils.isNull(formik.values.type)
    ? findOption(typeOptions, String(formik.values.type)?.toUpperCase())
    : null;

  const priorityOptions = Object.keys(Comms.PRIORITY).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(t),
  }));
  const priorityDefaultOption = !Utils.isNull(formik.values.priority)
    ? findOption(priorityOptions, String(formik.values.priority)?.toUpperCase())
    : null;

  const statusOptions = Object.keys(Comms.STATUS).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));
  const statusDefaultOption = !Utils.isNull(formik.values.status)
    ? findOption(statusOptions, String(formik.values.status)?.toUpperCase())
    : null;

  const tags = Object.keys(Comms.TAGS).map((t) => ({
    value: t,
    label: Utils.textFirstOnlyUpper(Utils.underscoreToSpace(t)),
  }));

  const getUserRentings = async () => {
    const Rentings = await Renting.provideTotalBill(
      await new Parse.Query("Rentings")
        .equalTo("driver", {
          __type: "Pointer",
          className: "_User",
          objectId: userId,
        })
        .limit(10000)
        .find({ useMasterKey: true })
    );

    setRentingOptions(
      Rentings.map((r) => ({
        value: r.id,
        label: `ID: ${r.id}, ${r.attributes.car.attributes.brand} ${
          r.attributes.car.attributes.model
        }, ${dayjs(r.attributes.startTime).format("DD/MMM/YY HH:mm")}-${dayjs(
          r.attributes.endTime
        ).format("HH:mm")}, ${parseFloat(r.attributes.totalBill).toFixed(2)}€`,
      }))
    );
  };

  const handleFormChange = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleSetTag = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags((prev) => {
        const newTags = prev.filter((t) => t !== tag);
        formik.setFieldValue("tags", newTags);
        return newTags;
      });
    } else {
      setSelectedTags((prev) => {
        const newTags = [...prev, tag];
        formik.setFieldValue("tags", newTags);
        return newTags;
      });
    }
  };

  const handleSubmit = () => {
    Object.keys(formik.values).forEach((key) => formik.setFieldTouched(key));

    if (!formik.isValid) {
      setCommSuccess(false);
    } else {
      handleSubmitCommunication(formik.values, editingComm?.id ?? null).then((r) => {
        if (r) {
          setCommSuccess(true);
          setTimeout(() => {
            formik.resetForm();
            toggleModal(false);
            setCommSuccess(false);
            setEditingCom(null);
            formik.validateForm();
          }, 1500);
        } else {
          setCommSuccess(false);
          toaster.error("Something went wrong. Try again.");
        }
      });
    }
  };

  const handleCancel = () => {
    toggleModal(false);
    formik.resetForm();
    setEditingCom(null);
    formik.validateForm();
  };

  useEffect(() => {
    formik.setValues(initializeForm);
  }, [editingComm]);

  useEffect(() => {
    if (!Utils.isNull(formik.values.renting)) {
      setRentingDefaultOption(findOption(rentingOptions, String(formik.values.renting)));
    } else {
      setRentingDefaultOption(null);
    }
  }, [formik.values]);

  useEffect(() => {
    getUserRentings();
    formik.validateForm();
  }, []);

  return (
    <Modal
      open={isOpen}
      title='Communication'
      classes={"w-11/12 md:w-1/2 lg:w-3/5 xl:w-1/2"}
      handleToggle={() => toggleModal(!isOpen)}
    >
      <div className='grid grid-cols-2 gap-1 items-start'>
        <div className='col-span-1'>
          <FormSelect
            label='Type'
            options={typeOptions}
            disabled={!!editingComm}
            value={typeDefaultOption}
            errors={formik.touched.type && formik.errors.type}
            handleChange={(value) => handleFormChange("type", value.value)}
          />
        </div>
        <div className='col-span-1'>
          <FormSelect
            label='Priority'
            disabled={!!editingComm}
            options={priorityOptions}
            value={priorityDefaultOption}
            errors={formik.touched.priority && formik.errors.priority && formik.errors.priority}
            handleChange={(value) => handleFormChange("priority", value.value)}
          />
        </div>
        <div className='col-span-1'>
          <FormSelect
            label='Status'
            options={statusOptions}
            value={statusDefaultOption}
            errors={formik.touched.status && formik.errors.status}
            handleChange={(value) => handleFormChange("status", value.value)}
          />
        </div>
        <div className='col-span-1'>
          <FormDatepicker
            withTime={true}
            label='Follow Up'
            isClearable={true}
            value={formik.values.followup}
            handleChange={(value) => handleFormChange("followup", value ?? null)}
          />
        </div>
        <div className='col-span-2'>
          <div>
            <span className='inline-block text-sm sm:text-base text-text-main mb-1 font-medium pl-1'>
              Tags
            </span>
            <div className='flex flex-wrap items-center gap-1 p-2 bg-bg-input border border-solid border-border-input rounded'>
              {tags.map((t) => (
                <div
                  key={t.value}
                  className={`rounded-lg px-1 text-sm cursor-pointer ${
                    selectedTags.includes(t.value)
                      ? "bg-gray-300 text-gray-900"
                      : "bg-bg-modal text-text-main"
                  }`}
                  onClick={() => handleSetTag(t.value)}
                >
                  {t.label}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='col-span-2'>
          <FormSelect
            isClearable={true}
            options={rentingOptions}
            disabled={!!editingComm && !!editingComm.attributes.renting?.id}
            value={rentingDefaultOption}
            label='Associate with Renting'
            errors={formik.touched.renting && formik.errors.renting}
            handleChange={(value) => handleFormChange("renting", value?.value)}
          />
        </div>
        <div className='col-span-2'>
          <FormInput
            type='text'
            label='Subject'
            placeholder='Subject'
            disabled={!!editingComm}
            value={formik.values.subject}
            errors={formik.touched.subject && formik.errors.subject}
            handleChange={(e) => handleFormChange("subject", e.target.value)}
          />
        </div>
        <div className='col-span-2'>
          <FormTextarea
            rows={2}
            label='Content'
            value={formik.values.content}
            placeholder='Type the communication conten'
            errors={formik.touched.content && formik.errors.content}
            handleChange={(value) => handleFormChange("content", value)}
          />
        </div>
        <div className='col-span-2'>
          <FileUploadDropzone
            isMultifile={false}
            title='Drop files here'
            acceptedTypes={["pdf", "jpg", "jpeg", "png"]}
            emitFiles={(file) => handleFormChange("attachment", file)}
          />
        </div>

        <div className='col-span-2 grid grid-cols-2 gap-2 mt-2 sm:mt-4'>
          <Button classes='bg-gray-200 py-2' isLoading={isLoading} handleClick={handleCancel}>
            <span>Cancel</span>
          </Button>
          <Button
            isLoading={isLoading}
            handleClick={handleSubmit}
            disabled={isLoading || commSuccess}
            classes={`bg-blue-500 py-2 ${isLoading ? "pr-7" : "pr-2"}`}
          >
            <span className='text-white'>Add</span>
            {commSuccess && <FontAwesomeIcon icon={faCheckCircle} className='ml-2 text-white' />}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CommsModal;
