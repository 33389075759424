import { Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faEuro, faPercentage, faUserGroup } from "@fortawesome/free-solid-svg-icons";

export default class Discount {
  static TYPE = {
    FIX: "FIX",
    RIDE: "RIDE",
    REFERRAL: "REFERRAL",
    PERCENTAGE: "PERCENTAGE",
  };

  static getAmountSymbol = (type) => {
    if (type === "FIX") {
      return (
        <Tooltip title='Fixed Discount' placement='top'>
          <FontAwesomeIcon size='sm' icon={faEuro} className='cursor-pointer' />
        </Tooltip>
      );
    } else if (type === "PERCENTAGE") {
      return (
        <Tooltip title='Percentage Discount' placement='top'>
          <FontAwesomeIcon size='sm' icon={faPercentage} className='cursor-pointer' />
        </Tooltip>
      );
    } else if (type === "REFERRAL") {
      return (
        <Tooltip title='Referral Discount' placement='top'>
          <FontAwesomeIcon size='sm' icon={faUserGroup} className='cursor-pointer' />
        </Tooltip>
      );
    } else if (type === "RIDE") {
      return (
        <Tooltip title='Ride Discount' placement='top'>
          <FontAwesomeIcon size='sm' icon={faCar} className='cursor-pointer' />
        </Tooltip>
      );
    }
  };
}
