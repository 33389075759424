import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { THEMES } from "../../../store/actions/theme";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, CategoryScale, registerables } from "chart.js";
ChartJS.register(CategoryScale, ChartDataLabels, ...registerables);

const MostRidedCars = ({ cars }) => {
  const THEME = useSelector((state) => state?.theme);

  const chartData = {
    labels: cars?.map((i) => i.car.carPlate),
    datasets: [
      {
        label: "Cars",
        data: cars?.map((i) => i.count),
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        ticks: {
          color: THEME === THEMES.DARK ? "#fff" : "#000", // Change the color of x-axis labels
        },
      },
      y: {
        ticks: {
          color: THEME === THEMES.DARK ? "#fff" : "#000", // Change the color of y-axis labels
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          color: THEME === THEMES.DARK ? "#fff" : "#000",
          font: {
            size: 14,
          },
        },
      },
      datalabels: {
        color: "#ffffff",
        font: {
          size: 12,
        },
        anchor: "end",
        align: "start",
      },
      tooltip: {
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        backgroundColor: "#000000",
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const car = cars.find((i) => i.car.carPlate === context.label)?.car;
            return `${car.brand} ${car.model} ${value}`;
          },
        },
      },
    },
  };

  return (
    <div className='dashboard-block'>
      {!!!cars && <Loader isLoading={true} />}
      <div className='title'>
        <span>Most Rided Cars</span>
      </div>
      <div className='px-0 xl:px-10 2xl:px-20'>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default MostRidedCars;
