import { useCallback, useRef, useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointer } from "@fortawesome/free-regular-svg-icons";

const FileUploadDropzone = ({ title, classes, emitFiles, isMultifile, acceptedTypes }) => {
  const inputRef = useRef();
  const [files, setFiles] = useState();
  const [errors, setErrors] = useState(null);
  const [dragging, setDragging] = useState(false);
  const handleClick = useCallback(() => inputRef.current?.click(), []);

  const checkFileType = (file) => {
    const fileNameParts = file.name.split(".");
    const fileType = fileNameParts[fileNameParts.length - 1];
    return fileType;
  };

  const renderBorder = () => {
    if (!!errors) return "border-red-500";
    else if (dragging) return "border-blue-300";
    else if (!!files) return "border-blue-500";
    else return "border-gray-300";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setErrors(null);
    setDragging(true);
  };

  const handleUpload = (e) => {
    let noErrors = true;
    e.preventDefault();

    // Convert the filestream to array so that we can "play" with it
    let inputFiles = Array.from(!!e.dataTransfer ? e.dataTransfer.files : e.target.files);

    if (!inputFiles.length > 0) return;

    // Check for filetype errors
    inputFiles.forEach((file) => {
      if (!acceptedTypes.includes(String(checkFileType(file)).toLowerCase())) {
        setErrors(
          `Filetype not allowed. Only ${acceptedTypes.join(", ")} file types are accepted.`
        );
        setDragging(false);
        noErrors = false;
        return;
      }
    });

    if (noErrors) {
      // If user adds again a file that is already added, simply skip it
      let inputFilesUnique = [];
      if (!isMultifile) {
        inputFilesUnique = inputFiles;
      } else {
        inputFiles.forEach((file) => {
          if (!!files) {
            let found = false;
            files.forEach((stateFile) => {
              if (file.name === stateFile.name) {
                found = true;
              }
            });
            if (!found) inputFilesUnique.push(file);
          } else {
            inputFilesUnique = inputFiles;
          }
        });
      }

      if (!isMultifile) {
        setFiles([inputFilesUnique[0]]);
      } else if (files?.length > 0) {
        setFiles((prev) => {
          return [...prev, ...inputFilesUnique];
        });
      } else {
        setFiles(inputFilesUnique);
      }

      const filesToEmit = inputFilesUnique.length <= 1 ? inputFilesUnique[0] : inputFilesUnique;
      setDragging(false);
      emitFiles(filesToEmit);
    }
  };

  const handleRemove = (file) => {
    if (files.length <= 1) {
      setFiles(null);
    } else {
      setFiles((prev) => {
        let newArr = [...prev];
        newArr.splice(
          newArr.findIndex((f) => f.name === file.name),
          1
        );

        newArr = newArr.length < 1 ? null : newArr;
        emitFiles(newArr);
        return newArr;
      });
    }
    setErrors(null);
  };

  return (
    <div
      id='dropzone'
      onDrop={(e) => handleUpload(e)}
      onDragOver={(e) => handleDragOver(e)}
      onDragExit={() => setDragging(false)}
      onDragLeave={() => setDragging(false)}
      className={`relative w-full border-2 border-dashed bg-bg-card-main rounded ${classes} p-4 md:p-8 text-text-main ${renderBorder()}`}
    >
      {!!files ? (
        <>
          {files?.map((file, i) => (
            <div key={i} className='p-1 flex items-center gap-1'>
              <FontAwesomeIcon
                className='cursor-pointer'
                icon={faTimes}
                onClick={() => handleRemove(file)}
              />
              <div className='line-clamp-1' key={file.name}>
                <span className='w-fit'>{file?.name}</span>
              </div>
            </div>
          ))}

          {!!errors && (
            <div className='mt-4'>
              <span className='text-md text-red-500'>{errors}</span>
              <div className='cursor-pointer mt-4 text-text-main' onClick={handleClick}>
                <span>
                  Browse Files <FontAwesomeIcon icon={faHandPointer} />
                </span>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className='flex flex-col text-text-main text-sm sm:text-base'>
          {!!title && <span>{title}</span>}
          <span>Filetypes accepted are {acceptedTypes.join(", ")}.</span>
          <div className='cursor-pointer text-text-main mt-2' onClick={handleClick}>
            <span>
              Browse Files <FontAwesomeIcon icon={faHandPointer} />
            </span>
          </div>
        </div>
      )}
      <input
        name='file'
        type='file'
        ref={inputRef}
        className='hidden'
        multiple={isMultifile}
        onChange={(e) => handleUpload(e)}
      />
    </div>
  );
};

export default FileUploadDropzone;
