import { useEffect, useState } from "react";
import CarsTable from "../components/CarsTable";
import Loader from "../../../components/Loader";
import AddCarForm from "../components/AddCarForm";
import MapTracker from "./../components/MapTracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const CarsPageView = ({
  cars,
  sorting,
  isAdding,
  isLoading,
  mapBounds,
  pagination,
  setIsAdding,
  handleAddCar,
  expandedRows,
  inversDevices,
  totalElements,
  handleSorting,
  handlePageChange,
  handleExpandedRows,
  carProvidersOptions,
  handlePageSizeChange,
  handleToggleAvailable,
  handleToggleBluetooth,
  handleToggleChildSeat,
  handleTogglePetFriendly,
}) => {
  const [isInfoOpen, setInfoOpen] = useState(false);
  const [carHovered, setCarHovered] = useState(null);
  const [isMapExpanded, setMapExpanded] = useState(true);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [carStateCounters, setCarStateCounters] = useState({});

  const handleMarkerClick = (carId) => {
    setInfoOpen(true);
    setCarHovered(carId);
    setSelectedMarker(carId);
  };

  const handleCloseMarker = () => {
    setInfoOpen(false);
    setCarHovered(null);
    setSelectedMarker(null);
  };

  useEffect(() => {
    if (cars.length > 0) {
      let riding = 0;
      let available = 0;
      let maintenance = 0;
      let unavailable = 0;

      riding = cars.filter((c) => c.attributes.isRiding).length;
      maintenance = cars.filter((c) => c.attributes.underMaintenance).length;
      unavailable = cars.filter(
        (c) => !c.attributes.isAvailable && !c.attributes.underMaintenance
      ).length;
      available = cars.filter(
        (c) => c.attributes.isAvailable && !c.attributes.isRiding && !c.attributes.underMaintenance
      ).length;

      setCarStateCounters({
        riding: riding,
        available: available,
        unavailable: unavailable,
        maintenance: maintenance,
      });
    }
  }, [cars]);

  return (
    <div className='relative'>
      <Loader isLoading={isLoading} />
      <div className='z-10'></div>
      <div className='page-wrapper'>
        <Loader isLoading={isLoading} />
        <div className='sm:p-2 sm:rounded-xl sm:border sm:border-border-table-body relative'>
          <Accordion
            expanded={isMapExpanded}
            onChange={(e, isExpanded) => setMapExpanded(isExpanded)}
          >
            <AccordionSummary>
              <div className='flex w-full items-center justify-between px-2 pt-2 sm:px-0 sm:pt-0'>
                <div className='flex items-center flex-row gap-2'>Map</div>
                <div className='flex items-center gap-2'>
                  <span>{isMapExpanded ? "Collapse" : "Expand"}</span>
                  <FontAwesomeIcon icon={isMapExpanded ? faChevronUp : faChevronDown} />
                </div>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className='w-full h-[60vh]'>
                <MapTracker
                  cars={cars}
                  mapBounds={mapBounds}
                  isInfoOpen={isInfoOpen}
                  carHovered={carHovered}
                  setCarHovered={setCarHovered}
                  selectedMarker={selectedMarker}
                  handleMarkerClick={handleMarkerClick}
                  handleCloseMarker={handleCloseMarker}
                />
              </div>
            </AccordionDetails>
          </Accordion>
          <div className='grid grid-cols-2 sm:flex items-center sm:gap-4 justify-end py-2 sm:py-0 mt-0 sm:mt-2'>
            <div className='flex items-center gap-2 justify-center'>
              <div className='w-4 h-4 text-gray-900 bg-gray-900 rounded'></div>
              <span className='text-text-main'>{carStateCounters.available} Available</span>
            </div>
            <div className='flex items-center gap-2 justify-center'>
              <div className='w-4 h-4 text-gray-900 bg-green-600 rounded'></div>
              <span className='text-text-main'>{carStateCounters.riding} Riding</span>
            </div>
            <div className='flex items-center gap-2 justify-center'>
              <div className='w-4 h-4 text-gray-900 bg-orange-500 rounded'></div>
              <span className='text-text-main'>
                {carStateCounters.maintenance} Under Maintenance
              </span>
            </div>
            <div className='flex items-center gap-2 justify-center'>
              <div className='w-4 h-4 text-gray-900 bg-red-500 rounded'></div>
              <span className='text-text-main'>{carStateCounters.unavailable} Unavailable</span>
            </div>
          </div>
        </div>
        <div className='page-content rounded'>
          {isAdding && (
            <div className='p-4 mb-4 border border-border-table-body rounded-xl'>
              <AddCarForm
                isAdding={isAdding}
                setIsAdding={setIsAdding}
                handleAddCar={handleAddCar}
                inversDevices={inversDevices}
                carProvidersOptions={carProvidersOptions}
              />
            </div>
          )}
          <div className='table-wrapper mt-2'>
            <CarsTable
              cars={cars}
              sorting={sorting}
              isAdding={isAdding}
              isLoading={isLoading}
              pagination={pagination}
              carHovered={carHovered}
              setIsAdding={setIsAdding}
              expandedRows={expandedRows}
              totalElements={totalElements}
              handleSorting={handleSorting}
              handlePageChange={handlePageChange}
              handleMarkerClick={handleMarkerClick}
              handleExpandedRows={handleExpandedRows}
              handlePageSizeChange={handlePageSizeChange}
              handleToggleAvailable={handleToggleAvailable}
              handleToggleBluetooth={handleToggleBluetooth}
              handleToggleChildSeat={handleToggleChildSeat}
              handleTogglePetFriendly={handleTogglePetFriendly}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarsPageView;
