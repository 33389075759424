import Parse from "parse";
import { useFormik } from "formik";
import CAR from "../../../models/Car";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import { CITIES } from "../../../models/Baseline";
import { findOption } from "../../../utils/Forms";
import useToaster from "../../../hooks/useToaster";
import Button from "../../../components/form/Button";
import { boolean, number, object, string } from "yup";
import FormInput from "../../../components/form/FormInput";
import FormSelect from "../../../components/form/FormSelect";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import FormCheckbox from "../../../components/form/FormCheckbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import FileUploadDropzone from "../../../components/form/FileUploadDropzone";

const AddCarForm = ({
  isAdding,
  setIsAdding,
  handleAddCar,
  inversDevices,
  carProvidersOptions,
}) => {
  const toaster = useToaster();
  const [cars, setCars] = useState([]);
  const [carPhotoInput, setCarPhotoInput] = useState("SELECT");
  const [carPhotosOptions, setCarPhotosOptions] = useState([]);
  const [selectedCarPhoto, setSelectedCarPhoto] = useState(null);

  const yearOptions = Utils.getLastNYears(20).map((year) => ({
    value: year,
    label: year.toString(),
  }));
  const cityOptions = Object.keys(CITIES).map((key) => ({
    value: key,
    label: Utils.textFirstOnlyUpper(key),
  }));
  const fuelTypeOptions = Object.keys(CAR.FUELTYPES).map((key) => ({
    value: key,
    label: Utils.textFirstOnlyUpper(key),
  }));

  const validationSchema = object().shape({
    brand: string().required("Required field"),
    model: string().required("Required field"),
    price: number().required("Required field"),
    city: string().required("Required field"),
    owner: string().required("Required field"),
    carPlate: string().required("Required field"),
    fuelType: string().required("Required field"),
    seatsNum: string().required("Required field"),
    inversQNR: string().required("Required field"),
    isAvailable: boolean().required("Required field"),
    hasBluetooth: boolean().required("Required field"),
    hasChildSeat: boolean().required("Required field"),
    isPetFriendly: boolean().required("Required field"),
    autoTransmission: boolean().required("Required field"),
    manufacturingDate: string().required("Required field"),
    initialMileage: number()
      .required("Required field")
      .typeError("Initial Mileage must be a number"),
  });
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: {
      brand: "",
      model: "",
      price: 0,
      city: "",
      owner: "",
      fuelType: "",
      carPlate: "",
      photo: null,
      inversQNR: "",
      seatsNum: "",
      initialMileage: 0,
      hasBluetooth: false,
      hasChildSeat: false,
      isPetFriendly: false,
      autoTransmission: false,
      manufacturingDate: "",
      isAvailable: false,
    },
  });

  const getLabel = (device) => {
    const carPlate = device?.vehicle_information?.vehicle_license_plate
      ? device?.vehicle_information?.vehicle_license_plate
      : "No plate";
    return (
      <div className='flex items-center'>
        <FontAwesomeIcon
          icon={faCircle}
          size='xs'
          className={`${device.active ? "text-green-400" : "text-red-400"}`}
        />
        <span className='ml-1'>
          {device.qnr} - {carPlate}
        </span>
      </div>
    );
  };

  const inversDevicesOptions = inversDevices.map((device) => {
    return {
      value: device.qnr,
      label: getLabel(device),
    };
  });

  const getCarPhotos = async () => {
    const Cars = await new Parse.Query("Car")
      .notEqualTo("inversQNR", "ARISTEST")
      .find({ useMasterKey: true });
    setCars(Cars);

    const Photos = Cars.map((c) => c.get("photo"));

    let distinctPhotos = Photos.map((p) => {
      const nameParts = p._name.split("_");
      const name = nameParts.splice(1, nameParts.length).join("_");

      return { url: p._url, name: name };
    });

    distinctPhotos = distinctPhotos.filter(
      (obj, index, self) => index === self.findIndex((t) => t.name === obj.name)
    );

    distinctPhotos = distinctPhotos.sort((a, b) =>
      a.name > b.name ? 1 : b.name > a.name ? -1 : 0
    );

    setCarPhotosOptions(
      distinctPhotos.map((cp) => ({
        value: cp.url,
        label: (
          <div className='flex items-center'>
            <img src={cp.url} className='w-14 max-h-8 object-contain' />
            <span className='ml-1'>{cp.name}</span>
          </div>
        ),
      }))
    );
  };

  const handleCarPhotoChange = (field, value) => {
    if (field === "SELECT") {
      setSelectedCarPhoto(value?.value);
      if (value) {
        const car = cars.find((c) => c.attributes.photo._url === value.value);

        formik.setFieldValue("photo", car.attributes.photo);
      }
    } else {
      let fileBase64 = null;
      let fileReader = new FileReader();

      return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
          fileReader.abort();
          reject(() => {
            console.error("Problem parsing input file.");
            toaster.error("Problem parsing input file.");
          });
        };

        fileReader.onload = async (fileLoadedEvent) => {
          fileBase64 = fileLoadedEvent.target.result;
          const data = fileBase64.split(",")[1];

          const parseFile = new Parse.File(value.name, { base64: data });

          formik.setFieldValue("photo", parseFile);
        };

        fileReader.readAsDataURL(value);
      });
    }
  };

  const handleFormChange = (field, value) => {
    formik.setFieldTouched(field);
    formik.setFieldValue(field, value);
  };

  const handleSubmit = async () => {
    Object.keys(formik.values).forEach((key) => formik.setFieldTouched(key));

    if (formik.isValid) {
      await handleAddCar(formik);
    }
  };

  useEffect(() => {
    formik.validateForm();
    getCarPhotos();
  }, []);

  return (
    <>
      <div className='grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5'>
        <div className='flex items-start'>
          <FormInput
            type='text'
            label='Brand'
            placeholder='Car Brand'
            value={formik.values.brand}
            errors={formik.touched.brand && formik.errors.brand}
            handleChange={(e) => handleFormChange("brand", e.target.value)}
          />
        </div>
        <div className='flex items-start'>
          <FormInput
            type='text'
            label='Model'
            placeholder='Car Model'
            value={formik.values.model}
            errors={formik.touched.model && formik.errors.model}
            handleChange={(e) => handleFormChange("model", e.target.value)}
          />
        </div>
        <div className='flex items-start'>
          <FormInput
            type='text'
            label='Car Plate'
            placeholder='Car Plate'
            value={formik.values.carPlate}
            errors={formik.touched.carPlate && formik.errors.carPlate}
            handleChange={(e) => handleFormChange("carPlate", e.target.value)}
          />
        </div>
        <div>
          <div>
            <FormSelect
              label='Invers QNR'
              isClearable={true}
              isSearchable={true}
              options={inversDevicesOptions}
              errors={formik.touched.inversQNR && formik.errors.inversQNR}
              handleChange={(value) => handleFormChange("inversQNR", value?.value)}
              value={
                !Utils.isNull(formik.values.inversQNR)
                  ? findOption(inversDevicesOptions, formik.values.inversQNR)
                  : null
              }
            />
          </div>
        </div>
        <div>
          <div>
            <FormSelect
              label='Fuel Type'
              options={fuelTypeOptions}
              errors={formik.touched.fuelType && formik.errors.fuelType}
              handleChange={(value) => handleFormChange("fuelType", value.value)}
              value={
                !Utils.isNull(formik.values.fuelType)
                  ? findOption(fuelTypeOptions, formik.values.fuelType)
                  : null
              }
            />
          </div>
        </div>
        <div className='flex items-start'>
          <FormInput
            type='text'
            label='Mileage'
            value={formik.values.initialMileage}
            placeholder='Car Mileage Upon Acquisition'
            errors={formik.touched.initialMileage && formik.errors.initialMileage}
            handleChange={(e) => handleFormChange("initialMileage", e.target.value)}
          />
        </div>
        <div className='flex items-start'>
          <FormInput
            type='text'
            label='Price'
            value={formik.values.price}
            errors={formik.touched.price && formik.errors.price}
            handleChange={(e) => handleFormChange("price", e.target.value)}
          />
        </div>
        <div>
          <div>
            <FormSelect
              label='City'
              options={cityOptions}
              errors={formik.touched.city && formik.errors.city}
              handleChange={(value) => handleFormChange("city", value.label)}
              value={
                !Utils.isNull(formik.values.city)
                  ? findOption(cityOptions, formik.values.city.toUpperCase())
                  : null
              }
            />
          </div>
        </div>
        <div>
          <div>
            <FormSelect
              label='Car Provider'
              options={carProvidersOptions}
              errors={formik.touched.owner && formik.errors.owner}
              handleChange={(value) => handleFormChange("owner", value.value)}
              value={
                !Utils.isNull(formik.values.owner)
                  ? findOption(carProvidersOptions, formik.values.owner)
                  : null
              }
            />
          </div>
        </div>
        <div className='flex items-start'>
          <FormInput
            min='0'
            max='5'
            type='number'
            label='Number of Seats'
            value={formik.values.seatsNum}
            placeholder='Number of Seats'
            errors={formik.touched.seatsNum && formik.errors.seatsNum}
            handleChange={(e) => handleFormChange("seatsNum", e.target.value)}
          />
        </div>
        <div>
          <div>
            <FormSelect
              options={yearOptions}
              label='Manufacturing Date'
              errors={formik.touched.manufacturingDate && formik.errors.manufacturingDate}
              handleChange={(value) => handleFormChange("manufacturingDate", value.value)}
              value={
                !Utils.isNull(formik.values.manufacturingDate)
                  ? findOption(yearOptions, formik.values.manufacturingDate)
                  : null
              }
            />
          </div>
        </div>
        <div>
          {carPhotoInput === "SELECT" ? (
            <div className='flex items-start'>
              <FormSelect
                label='Car Photo'
                isClearable={true}
                options={carPhotosOptions}
                handleChange={(value) => handleCarPhotoChange("SELECT", value)}
                value={
                  !Utils.isNull(selectedCarPhoto)
                    ? findOption(carPhotosOptions, selectedCarPhoto)
                    : null
                }
              />
            </div>
          ) : (
            <FileUploadDropzone
              isMultifile={false}
              acceptedTypes={["jpg", "jpeg", "png"]}
              title='Drag and drop the contract file here.'
              emitFiles={(file) => handleCarPhotoChange("INPUT", file)}
            />
          )}
          <div className='text-sm text-text-main mt-1'>
            {carPhotoInput === "SELECT" ? (
              <>
                <div>Didn't find the photo?</div>
                <div
                  className='cursor-pointer link'
                  onClick={() => {
                    formik.setFieldValue("photo", null);
                    setCarPhotoInput("FILE");
                  }}
                >
                  Swap field!
                </div>
              </>
            ) : (
              <div
                className='cursor-pointer link'
                onClick={() => {
                  formik.setFieldValue("photo", null);
                  setCarPhotoInput("SELECT");
                }}
              >
                Swap back!
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='grid grid-cols-2 md:grid-cols-5 gap-2 my-4'>
        <FormCheckbox
          label='Auto Transmission'
          value={formik.values.autoTransmission}
          handleChange={(value) => handleFormChange("autoTransmission", value)}
        />
        <FormCheckbox
          label='Is Available'
          value={formik.values.isAvailable}
          handleChange={(value) => handleFormChange("isAvailable", value)}
        />
        <FormCheckbox
          label='Is Pet Friendly'
          value={formik.values.isPetFriendly}
          handleChange={(value) => handleFormChange("isPetFriendly", value)}
        />
        <FormCheckbox
          label='Has Bluetooth'
          value={formik.values.hasBluetooth}
          handleChange={(value) => handleFormChange("hasBluetooth", value)}
        />
        <FormCheckbox
          label='Has Child Seat'
          value={formik.values.hasChildSeat}
          handleChange={(value) => handleFormChange("hasChildSeat", value)}
        />
      </div>
      <div className='w-full flex gap-2 justify-end mt-4'>
        <Button classes='bg-bg-btn-cancel px-2 py-1' handleClick={() => setIsAdding(!isAdding)}>
          <div className='flex gap-1 items-center text-text-main'>
            <FontAwesomeIcon icon={faXmark} size='sm' />
            <span>Cancel</span>
          </div>
        </Button>
        {isAdding && (
          <Button handleClick={handleSubmit} classes='bg-blue-500 px-2 py-1'>
            <div className='flex gap-1 items-center text-white'>
              <FontAwesomeIcon icon={faCheck} size='sm' />
              <span>Add</span>
            </div>
          </Button>
        )}
      </div>
    </>
  );
};

export default AddCarForm;
