import Utils from "../utils/Utils";
import theme from "./reducers/theme";
import session from "./reducers/session";
import { createStore, combineReducers } from "redux";

const loadState = () => {
  try {
    const state = localStorage.getItem("app-state");
    if (Utils.isNull(state)) return { session: null, theme: "LIGHT" };
    return JSON.parse(state);
  } catch (e) {
    console.error("Store initial state error: ", e);
    return null;
  }
};

// Combine your reducers
const rootReducer = combineReducers({
  session,
  theme,
});

const store = createStore(rootReducer, loadState());

store.subscribe(() => {
  try {
    let state = { ...store.getState() };
    localStorage.setItem("app-state", JSON.stringify(state));
  } catch (e) {
    console.error("Store subscriber error: ", e);
  }
});

export default store;
