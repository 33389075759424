import { Pie } from "react-chartjs-2";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { THEMES } from "../../../store/actions/theme";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const AgeDemographics = ({ ageDemographics }) => {
  const THEME = useSelector((state) => state?.theme);

  const chartData = {
    labels: ["21 to 25", "26 to 30", "31 to 45", "46 to 60", "61+"],
    datasets: [
      {
        data: [
          ageDemographics?.from21to25 || 0,
          ageDemographics?.from26to30 || 0,
          ageDemographics?.from31to45 || 0,
          ageDemographics?.from46to60 || 0,
          ageDemographics?.from61 || 0,
        ],
        backgroundColor: ["#311393", "#4625b0", "#5734c7", "#6c49da", "#8563f2"],
        borderWidth: 0.5,
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        labels: {
          color: THEME === THEMES.DARK ? "#fff" : "#000",
          font: {
            size: 14,
          },
        },
      },
      datalabels: {
        color: "#ffffff",
        font: {
          size: 14,
          weight: "bold",
        },
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          const percentage = (
            (value / context.dataset.data.reduce((a, b) => a + b, 0)) *
            100
          ).toFixed(1);
          return `${label}\n${value} (${percentage}%)`;
        },
        anchor: "end",
        align: "start",
        offset: (context) => {
          // Apply offset only for the "Other" label
          const label = context.chart.data.labels[context.dataIndex];
          return label === "61+" ? 60 : 10; // Offset 40px for "Other", 10 for other labels
        },
      },
      anchor: "center",
      align: "center",
      tooltip: {
        titleColor: "#ffffff",
        bodyColor: "#ffffff",
        backgroundColor: "#000000",
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${value} (${percentage}%)`;
          },
        },
      },
    },
  };

  return (
    <div className='dashboard-block'>
      {!!!ageDemographics && <Loader isLoading={true} />}
      <div className='title'>
        <span>User Age Demographics</span>
      </div>
      <div className='px-0 xl:px-10 2xl:px-20'>
        <Pie data={chartData} options={chartOptions} />
      </div>
      <div className='text-center leading-0 mt-2'>
        <span className='text-sm leading-2'>
          Total: <b>{ageDemographics?.total}</b>
        </span>
        <br />
        <span className='text-sm'>of all users who have defined their age</span>
      </div>
    </div>
  );
};

export default AgeDemographics;
