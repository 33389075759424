import { useState } from "react";
import User from "../../../models/User";
import Status from "../components/Status";
import Selfie from "../components/Selfie";
import useUser from "../../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import UserNotes from "../components/UserNotes";
import UserDetails from "../components/UserDetails";
import PageMenu from "../../../components/PageMenu";
import DeleteUserModal from "../components/DeleteUserModal";
import CommsPageState from "../../comms/state/CommsPageState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import PaymentMethodsModal from "../components/PaymentMethodsModal";
import UserMessagesPageState from "./../state/UserMessagesPageState";
import RentingsPageState from "../../rentings/state/RentingsPageState";
import DiscountsPageState from "../../discounts/state/DiscountsPageState";
import UserAdditionalInfomration from "../components/UserAdditionInformation";
import UserAuthorizationControls from "../components/UserAuthorizationControls";
import UserPersonalDocumentation from "../components/UserPersonalDocumentation";
import UserAdditionalDocumentation from "../components/UserAdditionalDocumentation";
import {
  faEdit,
  faEuro,
  faMinus,
  faXmark,
  faTrashCan,
  faFloppyDisk,
  faCircleNotch,
  faArrowRightToBracket,
} from "@fortawesome/free-solid-svg-icons";

const UserPageView = ({
  user,
  form,
  totals,
  isLoading,
  isEditing,
  handleEdit,
  handleSave,
  paymentMethod,
  isLoadingForm,
  isLoadingTotals,
  handleFormChange,
  handleNotesSubmit,
  duplicateLicenseNo,
  handleToggleLocked,
  handleToggleEnabled,
  handleSubmitDocument,
  handleUserAuthorization,
  handleToggleEmailVerified,
  handleTogglePhoneVerified,
}) => {
  const userHook = useUser();
  const navigate = useNavigate();
  const hasSelfie = !!user.selfie;
  const [openTab, setTab] = useState(0);
  const [isDeleteModalOpen, toggleDeleteModal] = useState(false);
  const [shouldUpdateComms, setShouldUpdateComms] = useState(false);
  const allowEdit = userHook.getUserRoles()?.includes(User.ROLES.ADMIN);
  const [isPaymentMethodsModalOpen, togglePaymentMethodsModal] = useState(false);

  return (
    <div className='page-wrapper relative'>
      <div className='page-content'>
        <div className='page-title'>
          <div className='flex items-center gap-0 sm:gap-2 flex-col sm:flex-row'>
            <span className='title'>{user.username}</span>
            <Status isRejected={user.isRejected} isAuthorized={user.isAuthorized} />
          </div>
          <div className='flex items-center gap-2'>
            <div
              className='flex items-center justify-center w-8 h-8 bg-white border border-solid border-gray-200 rounded cursor-pointer'
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowRightToBracket} className='rotate-180' size='lg' />
            </div>
            {allowEdit && (
              <div className='flex items-center gap-2'>
                <div
                  className={`flex items-center justify-center w-8 h-8 bg-white border border-solid border-gray-200 rounded ${
                    isLoadingForm ? "cursor-not-allowed" : "cursor-pointer"
                  }`}
                  onClick={allowEdit && !isLoadingForm ? handleEdit : null}
                >
                  <FontAwesomeIcon
                    size='lg'
                    icon={isEditing ? faXmark : faEdit}
                    className={`${isEditing && "text-red-500"}`}
                  />
                </div>
                {isEditing && (
                  <div
                    className={`flex items-center justify-center w-8 h-8 bg-white border border-solid border-gray-200 rounded ${
                      isLoadingForm ? "cursor-not-allowed" : "cursor-pointer"
                    }`}
                    onClick={!isLoadingForm ? handleSave : null}
                  >
                    <FontAwesomeIcon
                      size='lg'
                      className={`${
                        isLoadingForm ? "animate-spin text-gray-400" : "text-green-600"
                      }`}
                      icon={isLoadingForm ? faCircleNotch : faFloppyDisk}
                    />
                  </div>
                )}
              </div>
            )}
            <PageMenu>
              <div
                className='flex items-center cursor-pointer'
                onClick={() => togglePaymentMethodsModal(true)}
              >
                <FontAwesomeIcon icon={faCreditCard} size='sm' />
                <span className='ml-2 text-sm'>Payment Methods</span>
              </div>
              <div
                className='flex items-center cursor-pointer'
                onClick={() => toggleDeleteModal(true)}
              >
                <FontAwesomeIcon icon={faTrashCan} size='sm' />
                <span className='ml-2 text-sm'>Delete User</span>
              </div>
            </PageMenu>
          </div>
        </div>
        <div className='page-totals'>
          <div>
            <span>Rentings</span>
            {isLoadingTotals ? (
              <FontAwesomeIcon
                icon={faCircleNotch}
                className='animate-spin mt-4 opacity-50'
                size='lg'
              />
            ) : (
              <span>
                {!!totals.rentings ? totals.rentings : <FontAwesomeIcon icon={faMinus} />}
              </span>
            )}
          </div>
          <div>
            <span>Points</span>
            <span>1000</span>
          </div>
          <div>
            <span>Revenue</span>
            {isLoadingTotals ? (
              <FontAwesomeIcon
                icon={faCircleNotch}
                className='animate-spin mt-4 opacity-50'
                size='lg'
              />
            ) : (
              <span>
                {!!totals.revenue ? (
                  <>
                    {parseFloat(totals.revenue).toFixed(2)}
                    <FontAwesomeIcon icon={faEuro} className='ml-1' />
                  </>
                ) : (
                  <FontAwesomeIcon icon={faMinus} />
                )}
              </span>
            )}
          </div>
          <div>
            <span>Damages</span>
            <span>
              <FontAwesomeIcon icon={faMinus} />
            </span>
          </div>
        </div>
        <div>
          <div className='tabs grid-cols-3'>
            <div
              onClick={() => setTab(0)}
              className={`tab ${openTab === 0 ? "active" : "inactive"}`}
            >
              <span>Personal Information</span>
            </div>
            <div
              onClick={() => setTab(1)}
              className={`tab ${openTab === 1 ? "active" : "inactive"}`}
            >
              <span>Rentings</span>
            </div>
            <div
              onClick={() => setTab(2)}
              className={`tab ${openTab === 2 ? "active" : "inactive"}`}
            >
              <span>Extra Information</span>
            </div>
          </div>
          <div className='py-4'>
            {openTab === 0 ? (
              <>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4'>
                  <div className='box-border-rounded with-bg'>
                    <UserDetails
                      user={user}
                      form={form}
                      isEditing={isEditing}
                      isLoadingForm={isLoadingForm}
                      handleFormChange={handleFormChange}
                      duplicateLicenseNo={duplicateLicenseNo}
                    />
                  </div>
                  <div className='box-border-rounded with-bg'>
                    <UserAuthorizationControls
                      user={user}
                      isLoading={isLoading}
                      paymentMethod={paymentMethod}
                      handleToggleLocked={handleToggleLocked}
                      handleToggleEnabled={handleToggleEnabled}
                      handleUserAuthorization={handleUserAuthorization}
                      handleToggleEmailVerified={handleToggleEmailVerified}
                      handleTogglePhoneVerified={handleTogglePhoneVerified}
                    />
                  </div>
                  {hasSelfie && (
                    <div className='box-border-rounded with-bg'>
                      <Selfie picture={user.selfie} />
                    </div>
                  )}
                  <div
                    className={`box-border-rounded with-bg ${
                      hasSelfie ? "col-span-1 md:col-span-2 lg:col-span-3" : ""
                    }`}
                  >
                    <UserPersonalDocumentation
                      id={user.idDocument}
                      isExpanded={hasSelfie}
                      license={user.license}
                      handleSubmitDocument={handleSubmitDocument}
                    />
                  </div>
                  <div className='box-border-rounded with-bg'>
                    <UserNotes user={user} handleNotesSubmit={handleNotesSubmit} />
                  </div>
                  <div className='box-border-rounded with-bg'>
                    <UserAdditionalInfomration user={user} />
                  </div>
                  <div className='box-border-rounded with-bg'>
                    <UserAdditionalDocumentation
                      id={user.idDocument}
                      license={user.license}
                      licenseIntl={user.licenseIntl}
                      residencyProof={user.residencyProof}
                      handleSubmitDocument={handleSubmitDocument}
                    />
                  </div>
                </div>
              </>
            ) : openTab === 1 ? (
              <RentingsPageState userId={user.id} />
            ) : (
              <div className='flex flex-col gap-y-4'>
                <div>
                  <UserMessagesPageState
                    userId={user.id}
                    shouldUpdateComms={setShouldUpdateComms}
                  />
                </div>
                <div>
                  <CommsPageState userId={user.id} shouldUpdate={shouldUpdateComms} />
                </div>
                <div>
                  <DiscountsPageState userId={user.id} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <DeleteUserModal
        user={user}
        isOpen={isDeleteModalOpen}
        toggleModal={toggleDeleteModal}
        isPaymentMethodsModalOpen={isPaymentMethodsModalOpen}
        togglePaymentMethodsModal={togglePaymentMethodsModal}
      />
      <PaymentMethodsModal
        user={user}
        isOpen={isPaymentMethodsModalOpen}
        toggleModal={togglePaymentMethodsModal}
      />
    </div>
  );
};

export default UserPageView;
