import MostRidedCars from "../components/MostRidedCars";
import MostRidingUsers from "../components/MostRidingUsers";
import AgeDemographics from "../components/AgeDemographics";
import UserMetricsBlock from "../components/UserMetricsBlock";
import RideMetricsBlock from "../components/RideMetricsBlock";
import GenderDemographics from "../components/GenderDemographics";
import RentingMetricsBlock from "../components/RentingMetricsBlock";
import RevenueMetricsBlock from "../components/RevenueMetricsBlock";

const DashboardPageView = ({
  userTotals,
  rideTotals,
  revenueTotals,
  rentingTotals,
  mostRidedCars,
  mostRidingUsers,
  ageDemographics,
  genderDemographics,
}) => {
  return (
    <div className='page-wrapper'>
      <div className='page-content'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
          <UserMetricsBlock userTotals={userTotals} />
          <RentingMetricsBlock rentingTotals={rentingTotals} />
          <RideMetricsBlock rideTotals={rideTotals} />
          <RevenueMetricsBlock revenueTotals={revenueTotals} />
          <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
            <MostRidingUsers users={mostRidingUsers} />
          </div>
          <div className='col-span-1 sm:col-span-2 lg:col-span-4'>
            <MostRidedCars cars={mostRidedCars} />
          </div>
          <div className='col-span-1 lg:col-span-2'>
            <GenderDemographics genderDemographics={genderDemographics} />
          </div>
          <div className='col-span-1 lg:col-span-2'>
            <AgeDemographics ageDemographics={ageDemographics} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPageView;
