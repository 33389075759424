import dayjs from "dayjs";
import Loader from "../../Loader";
import { useFormik } from "formik";
import { Tooltip } from "@mui/material";
import Utils from "../../../utils/Utils";
import { isEqual, isNumber } from "lodash";
import { object, number, date } from "yup";
import { useEffect, useState } from "react";
import FormInput from "../../form/FormInput";
import FormSelect from "../../form/FormSelect";
import Discount from "../../../models/Discount";
import { findOption } from "../../../utils/Forms";
import FormTextarea from "../../form/FormTextarea";
import { dateFormatter } from "../../../utils/Dates";
import FormDatepicker from "../../form/FormDatepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTags,
  faXmark,
  faCheck,
  faPencil,
  faCalendarDay,
} from "@fortawesome/free-solid-svg-icons";

const CampaignCardView = ({ campaign, isLoading, handleSubmit, discountsGenerated }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const isPast = dayjs(campaign.attributes.end).isBefore(dayjs());
  const isAfter = dayjs(dayjs()).isAfter(campaign.attributes.start);

  const testValidationDates = (context) => {
    const end = context.parent.end ? context.parent.end : null;
    const start = context.parent.start ? context.parent.start : null;
    const discountExpiry = context.parent.discountExpiry ? context.parent.discountExpiry : null;

    if (
      !!start &&
      !!end &&
      (dayjs(discountExpiry).isBefore(dayjs(start)) || dayjs(discountExpiry).isBefore(dayjs(end)))
    ) {
      return false;
    } else if (!!start && dayjs(discountExpiry).isBefore(dayjs(start))) {
      return false;
    } else if (!!end && dayjs(discountExpiry).isBefore(dayjs(end))) {
      return false;
    } else {
      return true;
    }
  };

  const validationSchema = object().shape({
    discountAmount: number().required("Required").typeError("Number!"),
    discountExpiry: date()
      .nullable()
      .test("validDates", "Please select a valid date", (value, context) => {
        return testValidationDates(context);
      }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: {
      discountType: campaign.attributes.discountType,
      discountAmount: campaign.attributes.discountAmount,
      start: campaign.attributes.start,
      end: campaign.attributes.end,
      comments: campaign.attributes.comments,
      discountExpiry: campaign.attributes.discountExpiry
        ? campaign.attributes.discountExpiry
        : null,
    },
  });

  const discountTypeOptions = Object.keys(Discount.TYPE)
    .filter((d) => d !== Discount.TYPE.RIDE)
    .filter((d) => d !== Discount.TYPE.REFERRAL)
    .map((key) => ({
      value: key,
      label: Utils.textFirstOnlyUpper(key),
    }));
  const discountTypeDfaultOption = !Utils.isNull(formik.values.discountType)
    ? findOption(discountTypeOptions, formik.values.discountType)
    : null;

  const getAmountSymbol = () => {
    return Discount.getAmountSymbol(campaign.attributes.discountType);
  };

  const handleToggleEdit = () => {
    if (isEditing) {
      formik.setFieldValue("end", formik.initialValues.end);
      formik.setFieldValue("start", formik.initialValues.start);
      formik.setFieldValue("discountType", formik.initialValues.discountType);
      formik.setFieldValue("discountAmount", formik.initialValues.discountAmount);
      formik.setFieldValue("discountExpiry", formik.initialValues.discountExpiry);
    }
    setIsEditing(!isEditing);
  };

  const submitForm = () => {
    handleSubmit(formik);
    setIsEditing(false);
  };

  useEffect(() => {
    if (!isEqual(formik.values, formik.initialValues) && formik.isValid) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  }, [formik.values]);

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isPast && (
        <div className='edit'>
          <Tooltip title={isEditing ? "Discard" : "Edit"} placement='top'>
            <FontAwesomeIcon
              className='w-4 h-4'
              icon={isEditing ? faXmark : faPencil}
              onClick={handleToggleEdit}
            />
          </Tooltip>
          {canSubmit && (
            <Tooltip title='Submit' placement='top'>
              <FontAwesomeIcon icon={faCheck} className='w-4 h-4' onClick={submitForm} />
            </Tooltip>
          )}
        </div>
      )}
      <div className='card z-10 relative' key={campaign.id}>
        <div className='title'>
          <span className='text-lg md:text-2xl font-bold'>{campaign.attributes.code}</span>
          <div className='flex items-start gap-1'>
            {isEditing ? (
              <div className='max-w-48'>
                <div className='flex items-start gap-1'>
                  <FontAwesomeIcon size='xl' icon={faTags} className='-rotate-90 mt-2' />
                  <div className='w-24 sm:w-32'>
                    <FormSelect
                      placeholder='Type'
                      disabled={isAfter}
                      isClearable={false}
                      options={discountTypeOptions}
                      value={discountTypeDfaultOption}
                      handleChange={(value) => formik.setFieldValue("discountType", value.value)}
                    />
                  </div>
                  <div className='w-12'>
                    <FormInput
                      type='text'
                      disabled={isAfter}
                      placeholder='Amount'
                      value={formik.values.discountAmount}
                      errors={formik.touched.discountAmount && formik.errors.discountAmount}
                      handleChange={(e) => {
                        formik.setFieldTouched("discountAmount");
                        formik.setFieldValue(
                          "discountAmount",
                          isNumber(e.target.value) ? Number(e.target.value) : e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className='flex flex-col items-end'>
                <div className='flex flex-nowrap items-center gap-0.5 text-base md:text-xl font-medium'>
                  <FontAwesomeIcon size='sm' icon={faTags} className='-rotate-90' />{" "}
                  {campaign.attributes.discountAmount} {getAmountSymbol()}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='text-center mb-2'>
          <div className='flex items-center justify-center gap-2'>
            {!!discountsGenerated || discountsGenerated === 0 ? (
              <span className='text-xl font-bold'>{discountsGenerated}</span>
            ) : (
              <div className='w-6 h-5 rounded bg-gray-200 bg-opacity-50 animate-pulse'></div>
            )}{" "}
            <span className='text-lg font-medium'>discounts generated</span>
          </div>
          {isEditing ? (
            <div className='mt-1 w-52 sm:w-64 mx-auto'>
              <FormDatepicker
                withTime={true}
                isClearable={false}
                label='Discount expiration date'
                value={dayjs(formik.values.discountExpiry)}
                errors={formik.touched.discountExpiry && formik.errors.discountExpiry}
                handleChange={(value) => {
                  formik.setFieldTouched("discountExpiry");
                  formik.setFieldValue("discountExpiry", value);
                }}
              />
            </div>
          ) : (
            !!campaign.attributes.discountExpiry && (
              <div className='font-medium'>
                <span>Discount expiry:</span>{" "}
                <span>{dateFormatter(campaign.attributes.discountExpiry, true)}</span>
              </div>
            )
          )}
        </div>
        {isEditing ? (
          <div className='max-w-98 mx-auto mb-2'>
            <FormTextarea
              rows='1'
              label='Comments'
              value={formik.values.comments}
              placeholder='Add comments for the campaign here..'
              errors={formik.touched.comments && formik.errors.comments}
              handleChange={(value) => {
                formik.setFieldTouched("comments");
                formik.setFieldValue("comments", value);
              }}
            />
          </div>
        ) : (
          !!campaign.attributes.comments && (
            <div className='max-w-68 mx-auto mb-2'>
              <div className='break-all text-justify'>
                <span>{campaign.attributes.comments}</span>
              </div>
            </div>
          )
        )}
        <div className='dates'>
          <div className='date'>
            <div className='title'>
              <span className=''>Starts:</span>
            </div>

            {isEditing ? (
              <div className='w-34 sm:w-40 md:w-52'>
                <FormDatepicker
                  withTime={true}
                  disabled={isAfter}
                  isClearable={false}
                  value={dayjs(formik.values.start)}
                  handleChange={(value) => formik.setFieldValue("start", value)}
                />
              </div>
            ) : (
              <div className='date-wrapper'>
                <div className='dmy'>
                  <span className='flex items-center gap-3'>
                    <FontAwesomeIcon icon={faCalendarDay} />{" "}
                    {dayjs(campaign.attributes.start).format("DD MMM")}{" "}
                    {dayjs(campaign.attributes.start).format("YYYY")}
                  </span>
                </div>
                <div className='time'>
                  <span>{dayjs(campaign.attributes.start).format("HH:mm")}</span>
                </div>
              </div>
            )}
          </div>
          <div className='date'>
            <div className='title'>
              <span className=''>Ends:</span>
            </div>
            {isEditing ? (
              <div className='w-34 sm:w-40 md:w-52'>
                <FormDatepicker
                  withTime={true}
                  isClearable={false}
                  value={dayjs(formik.values.end)}
                  handleChange={(value) => formik.setFieldValue("end", value)}
                />
              </div>
            ) : (
              <div className='date-wrapper'>
                <div className='dmy'>
                  <span className='flex items-center gap-3'>
                    <FontAwesomeIcon icon={faCalendarDay} />{" "}
                    {dayjs(campaign.attributes.end).format("DD MMM")}{" "}
                    {dayjs(campaign.attributes.end).format("YYYY")}
                  </span>
                </div>
                <div className='time'>
                  <span>{dayjs(campaign.attributes.end).format("HH:mm")}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CampaignCardView;
