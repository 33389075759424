import Select from "react-select";
import { forwardRef } from "react";
import AsyncSelect from "react-select/async";

const FormSelect = forwardRef(
  (
    {
      value,
      label,
      errors,
      isAsync,
      isMulti,
      options,
      classes,
      disabled,
      isClearable,
      placeholder,
      isSearchable,
      handleChange,
      cacheOptions,
      defaultOptions,
      promiseOptions,
      isOptionDisabled,
      handleInputChange,
    },
    ref
  ) => {
    const customStyles = {
      singleValue: (base) => ({
        ...base,
        color: "rgba(var(--text-main))",
      }),
      input: (base) => ({
        ...base,
        color: "rgba(var(--text-main), 0.7)",
      }),
      control: (base, state) => ({
        ...base,
        opacity: state.isDisabled ? "80%" : "",
        backgroundColor: "rgba(var(--bg-input))",
        cursor: state.isDisabled ? "not-allowed" : "pointer",
        border: !!errors ? "1px solid rgba(211, 47, 47, 1)" : "1px solid rgba(var(--border-input))",
        // Removes weird border around container
        boxShadow: state.isFocused ? null : null,
        "&:hover": {
          border: "1px solid rgba(var(--border-input-hover))",
        },
        "&:focus": {
          border: "1px solid rgba(var(--border-input-focus))",
        },
      }),
      menu: (base) => ({
        ...base,
        margin: "2px 0",
        color: "rgba(var(--text-main))",
        backgroundColor: "rgba(var(--bg-input))",
      }),
      option: (base, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...base,
          cursor: isDisabled ? "not-allowed" : "pointer",
          color: isSelected && "rgba(var(--text-main))",
          backgroundColor: isFocused && "rgba(var(--bg-select-option-hover))",
        };
      },
    };

    return (
      <div className='w-full h-full form-control relative'>
        {label && <label className='pl-1 mb-1'>{label}</label>}
        {isAsync ? (
          <AsyncSelect
            ref={ref}
            className={classes}
            isDisabled={disabled}
            styles={customStyles}
            onChange={handleChange}
            isClearable={isClearable}
            placeholder={placeholder}
            cacheOptions={cacheOptions}
            loadOptions={promiseOptions}
            defaultOptions={defaultOptions}
            onInputChange={handleInputChange}
          />
        ) : (
          <Select
            value={value}
            options={options}
            isMulti={isMulti}
            className={classes}
            menuPlacement='bottom'
            isDisabled={disabled}
            styles={customStyles}
            onChange={handleChange}
            placeholder={placeholder}
            isClearable={isClearable}
            isSearchable={isSearchable}
            onInputChange={handleInputChange}
            isOptionDisabled={isOptionDisabled}
          />
        )}
        {errors && (
          <div className='error'>
            <span>{errors}</span>
          </div>
        )}
      </div>
    );
  }
);

export default FormSelect;
