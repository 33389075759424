import User from "../../../models/User";
import Utils from "../../../utils/Utils";
import { useEffect, useState } from "react";
import Filters from "../components/Filters";
import UsersTable from "../components/UsersTable";
import ExportModal from "../components/ExportModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SendUserMessagesModal from "../components/SendUserMessagesModal";
import AddUserDiscountsModal from "../../discounts/components/AddUserDiscountsModal";
import {
  faTag,
  faMinus,
  faTimes,
  faEnvelope,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";

const UsersPageView = ({
  users,
  totals,
  sorting,
  filters,
  isLoading,
  tabTotals,
  pagination,
  totalElements,
  handleSorting,
  exportOptions,
  isLoadingTotals,
  isLoadingExport,
  setExportOptions,
  handleExportData,
  handlePageChange,
  isExportModalOpen,
  toggleExportModal,
  isLoadingMessages,
  isLoadingDiscounts,
  handleClearFilters,
  handleFilterChange,
  handleApplyFilters,
  handleAddDiscounts,
  handleSendMessages,
  handlePageSizeChange,
  handleOpenExportModal,
}) => {
  const [openTab, setTab] = useState(0);
  const [selection, setSelection] = useState([]);
  const [isMessagesModalOpen, toggleMessagesModal] = useState(false);
  const [isDiscountsModalOpen, toggleDiscountsModal] = useState(false);

  const hasSelected = () => {
    return selection.length > 0;
  };

  const handleRemoveRecipient = (userId) => {
    setSelection(selection.filter((id) => id !== userId));
    if (selection.length <= 1) {
      toggleMessagesModal(false);
      toggleDiscountsModal(false);
    }
  };

  useEffect(() => {
    switch (filters.status) {
      case User.STATUS.PENDING:
        setTab(0);
        break;
      case User.STATUS.AUTHORIZED:
        setTab(1);
        break;
      case User.STATUS.REAUTHORIZED:
        setTab(2);
        break;
      case User.STATUS.UNAUTHORIZED:
        setTab(3);
        break;
      default:
        break;
    }
  }, [filters]);

  return (
    <div className='page-wrapper relative'>
      <div className='page-content'>
        <div className='page-title'>
          <span className='title'>USERS</span>
          <div className='flex gap-2 items-center justify-end'>
            {selection.length > 0 && (
              <div
                className={`title-button ${selection.length < 1 ? "disabled" : "enabled"}`}
                onClick={selection.length > 0 ? () => setSelection([]) : null}
              >
                <FontAwesomeIcon icon={faTimes} />
                <span>Clear</span>
              </div>
            )}
            <div
              className={`title-button ${!hasSelected() ? "disabled" : "enabled"}`}
              onClick={() => (hasSelected() ? toggleMessagesModal(true) : null)}
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <span>{selection.length > 1 ? "Send Messages" : "Send Message"}</span>
            </div>
            <div
              className={`title-button ${!hasSelected() ? "disabled" : "enabled"}`}
              onClick={() => (hasSelected() ? toggleDiscountsModal(true) : null)}
            >
              <FontAwesomeIcon icon={faTag} />
              <span>{selection.length > 1 ? "Add Discounts" : "Add Discount"}</span>
            </div>
          </div>
        </div>
        <Filters
          filters={filters}
          isLoading={isLoading}
          isLoadingTotals={isLoadingTotals}
          handleFilterChange={handleFilterChange}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
          handleOpenExportModal={handleOpenExportModal}
        />
        <div className='page-totals'>
          <div>
            <span>Total Users</span>
            {isLoadingTotals ? (
              <FontAwesomeIcon
                icon={faCircleNotch}
                className='animate-spin mt-4 opacity-50'
                size='lg'
              />
            ) : (
              <span>{!!totals.users ? totals.users : <FontAwesomeIcon icon={faMinus} />}</span>
            )}
          </div>
          <div>
            <span>Pending</span>
            {isLoadingTotals ? (
              <FontAwesomeIcon
                icon={faCircleNotch}
                className='animate-spin mt-4 opacity-50'
                size='lg'
              />
            ) : (
              <span>{!!totals.pending ? totals.pending : <FontAwesomeIcon icon={faMinus} />}</span>
            )}
          </div>
          <div>
            <span>Authorized</span>
            {isLoadingTotals ? (
              <FontAwesomeIcon
                icon={faCircleNotch}
                className='animate-spin mt-4 opacity-50'
                size='lg'
              />
            ) : (
              <span>
                {!!totals.authorized ? totals.authorized : <FontAwesomeIcon icon={faMinus} />}
              </span>
            )}
          </div>
          <div>
            <span>Registered Today</span>
            {isLoadingTotals ? (
              <FontAwesomeIcon
                icon={faCircleNotch}
                className='animate-spin mt-4 opacity-50'
                size='lg'
              />
            ) : (
              <span>
                {!!totals.registeredToday ? (
                  totals.registeredToday
                ) : (
                  <FontAwesomeIcon icon={faMinus} />
                )}
              </span>
            )}
          </div>
        </div>
        <div className='tabs grid-cols-4 mb-4'>
          <div
            className={`tab ${openTab === 0 ? "active" : "inactive"}`}
            onClick={() => handleFilterChange("status", { value: User.STATUS.PENDING }, true)}
          >
            <span>
              <div>Pending</div>
              <span className='text-sm -ml-1 sm:ml-0 sm:text-base'>
                (
                {!Utils.isNull(tabTotals?.pending) ? (
                  tabTotals.pending
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    className='animate-spin mt-4 opacity-50'
                    size='sm'
                  />
                )}
                )
              </span>
            </span>
          </div>
          <div
            className={`tab ${openTab === 1 ? "active" : "inactive"}`}
            onClick={() => handleFilterChange("status", { value: User.STATUS.AUTHORIZED }, true)}
          >
            <span>
              <div>Authorized</div>
              <span className='text-sm -ml-1 sm:ml-0 sm:text-base'>
                (
                {!Utils.isNull(tabTotals?.auth) ? (
                  tabTotals.auth
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    className='animate-spin mt-4 opacity-50'
                    size='sm'
                  />
                )}
                )
              </span>
            </span>
          </div>
          <div
            className={`tab ${openTab === 2 ? "active" : "inactive"}`}
            onClick={() => handleFilterChange("status", { value: User.STATUS.REAUTHORIZED }, true)}
          >
            <span>
              <div>Reauthorized</div>
              <span className='text-sm -ml-1 sm:ml-0 sm:text-base'>
                (
                {!Utils.isNull(tabTotals?.reauth) ? (
                  tabTotals.reauth
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    className='animate-spin mt-4 opacity-50'
                    size='sm'
                  />
                )}
                )
              </span>
            </span>
          </div>
          <div
            className={`tab ${openTab === 3 ? "active" : "inactive"}`}
            onClick={() => handleFilterChange("status", { value: User.STATUS.UNAUTHORIZED }, true)}
          >
            <span>
              <div>Unauthorized</div>
              <span className='text-sm -ml-1 sm:ml-0 sm:text-base'>
                (
                {!Utils.isNull(tabTotals?.unauth) ? (
                  tabTotals.unauth
                ) : (
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    className='animate-spin mt-4 opacity-50'
                    size='sm'
                  />
                )}
                )
              </span>
            </span>
          </div>
        </div>
        <div className='table-wrapper'>
          <UsersTable
            users={users}
            sorting={sorting}
            selection={selection}
            isLoading={isLoading}
            pagination={pagination}
            setSelection={setSelection}
            totalElements={totalElements}
            handleSorting={handleSorting}
            handlePageChange={handlePageChange}
            handlePageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
      <SendUserMessagesModal
        users={selection}
        setSelection={setSelection}
        isOpen={isMessagesModalOpen}
        isLoading={isLoadingMessages}
        toggleModal={toggleMessagesModal}
        handleSendMessages={handleSendMessages}
        handleRemoveRecipient={handleRemoveRecipient}
      />
      <AddUserDiscountsModal
        users={selection}
        setSelection={setSelection}
        isOpen={isDiscountsModalOpen}
        isLoading={isLoadingDiscounts}
        toggleModal={toggleDiscountsModal}
        handleAddDiscounts={handleAddDiscounts}
        handleRemoveRecipient={handleRemoveRecipient}
      />
      <ExportModal
        isOpen={isExportModalOpen}
        isLoading={isLoadingExport}
        exportOptions={exportOptions}
        toggleModal={toggleExportModal}
        setExportOptions={setExportOptions}
        handleExportData={handleExportData}
      />
    </div>
  );
};

export default UsersPageView;
